import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "TagsDropdown", {
    plus_title: "New tag",
    placeholder: "Tags",
    setter_text: "{{count}} tags selected",
    create_tag: "Create tag",
    create_new_tag: "Create new tag",
});

i18next.addResourceBundle("fr", "TagsDropdown", {
    plus_title: "Nouveau tag",
    placeholder: "Tags",
    setter_text: "{{count}} tags sélectionnés",
    create_tag: "Créer un tag",
    create_new_tag: "Créer un nouveau tag",
});
