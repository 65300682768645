import i18n from "@app/i18n";
import {
    useCmsCsrdDisclosureRequirementsList,
    useCmsCsrdEsrssList,
    useGetPillarByEsrsId,
} from "@app/screens/Frameworks/framework/Csrd/data";
import {
    RESOURCE_NAME,
    keys,
} from "@app/screens/Frameworks/framework/Csrd/i18n";
import {
    cleanDrId,
    cleanEsrsId,
} from "@app/screens/Frameworks/framework/Csrd/services";
import { EnrichedCsrdDatapoint } from "@app/screens/Frameworks/framework/Csrd/types";
import UserMultiSelect from "@app/shared/components/UserMultiSelect";
import { NULL_TOKEN } from "@app/shared/utils/NULL_TOKEN";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import {
    CsrdDatapointStatus,
    MaterialityStatus,
} from "@generated/client/graphql";
import { type ColumnDef } from "@tanstack/react-table";
import { useAtom, useAtomValue } from "jotai";
import { useMemo, type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
    datapointStatusFilterAtom,
    disclosureRequirementIdFilterAtom,
    esrsIdFilterAtom,
    ownerIdsFilterAtom,
} from "./ctx";
import { DatapointValueCell } from "./datapointValueCell";

const ColHeaderSingleSelect: FC<{
    loading?: boolean;
    value?: string;
    onValueChange: (value: string) => void;
    options: { value: string; label: string }[];
    tooltipContent: string;
}> = ({ value, loading, onValueChange, options, tooltipContent }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const active = !!value && value !== NULL_TOKEN;
    return (
        <SingleSelect value={value} onValueChange={onValueChange}>
            <Tooltip>
                <Tooltip.Trigger>
                    <SingleSelect.Trigger>
                        <Button
                            active={active}
                            size="xs"
                            variant="outlined"
                            loading={loading}
                        >
                            <Button.Icon name="angleDown" />
                        </Button>
                    </SingleSelect.Trigger>
                </Tooltip.Trigger>
                <Tooltip.Content>
                    <Text variant="body-sm">{tooltipContent}</Text>
                </Tooltip.Content>
            </Tooltip>
            <SingleSelect.Content className="overflow-y-scroll max-h-[250px] scrollbar-hide">
                <SingleSelect.Option
                    value={NULL_TOKEN}
                    key={NULL_TOKEN}
                    label={t(keys.select_all)}
                />
                {options.map((option) => (
                    <SingleSelect.Option
                        value={option.value}
                        key={option.value}
                        label={option.label}
                    />
                ))}
            </SingleSelect.Content>
        </SingleSelect>
    );
};

const EsrsColHeader: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    const { esrssList, isPending } = useCmsCsrdEsrssList();
    const [esrs, setEsrs] = useAtom(esrsIdFilterAtom);

    const handleValueChange = (value: string) => {
        setEsrs(value === NULL_TOKEN ? undefined : value);
    };
    const options = useMemo(() => {
        return (
            esrssList?.map((esrs) => ({
                value: esrs.cmsId,
                label: cleanEsrsId(esrs.cmsId),
            })) ?? []
        );
    }, [esrssList]);

    return (
        <ColHeaderSingleSelect
            loading={isPending}
            value={esrs || NULL_TOKEN}
            onValueChange={handleValueChange}
            options={options}
            tooltipContent={t(keys.table_col_header_esrs)}
        />
    );
};

const DisclosureRequirementColHeader: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { disclosureRequirements, isPending } =
        useCmsCsrdDisclosureRequirementsList();

    const erss = useAtomValue(esrsIdFilterAtom);
    const [selectedDr, setSelectedDr] = useAtom(
        disclosureRequirementIdFilterAtom,
    );

    const handleValueChange = (value: string) => {
        setSelectedDr(value === NULL_TOKEN ? undefined : value);
    };

    const options = useMemo(() => {
        const base = erss
            ? disclosureRequirements?.byEsrsId[erss]
            : disclosureRequirements?.list;
        return (
            base?.map((dr) => ({
                value: dr.cmsId,
                label: cleanDrId(dr.cmsId),
            })) ?? []
        );
    }, [disclosureRequirements, erss]);

    return (
        <ColHeaderSingleSelect
            loading={isPending}
            value={selectedDr || NULL_TOKEN}
            onValueChange={handleValueChange}
            options={options}
            tooltipContent={t(keys.table_col_header_disclosure_requirement)}
        />
    );
};

const StatusColHeader: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [status, setStatus] = useAtom(datapointStatusFilterAtom);

    const handleValueChange = (value: string) => {
        if (value === NULL_TOKEN) {
            setStatus(undefined);
        } else {
            setStatus(value as CsrdDatapointStatus);
        }
    };

    const options = useMemo(() => {
        return [
            {
                value: MaterialityStatus.NotMaterial,
                label: t(keys.datapoint_status_not_material),
            },
            {
                value: CsrdDatapointStatus.NotStarted,
                label: t(keys.datapoint_status_not_started),
            },
            {
                value: CsrdDatapointStatus.Validated,
                label: t(keys.datapoint_status_validated),
            },
            {
                value: CsrdDatapointStatus.InProgress,
                label: t(keys.datapoint_status_in_progress),
            },
        ];
    }, [t]);

    return (
        <ColHeaderSingleSelect
            value={status || NULL_TOKEN}
            onValueChange={handleValueChange}
            options={options}
            tooltipContent={t(keys.table_col_header_status)}
        />
    );
};

const esrsCol: ColumnDef<EnrichedCsrdDatapoint> = {
    id: "esrs",
    header: EsrsColHeader,
    cell: ({ row }) => (
        <Text variant="body-sm-bold">
            {cleanEsrsId(row.original.esrsId ?? "")}
        </Text>
    ),
};

const disclosureRequirementCol: ColumnDef<EnrichedCsrdDatapoint> = {
    id: "disclosureRequirement",
    header: DisclosureRequirementColHeader,
    cell: ({ row }) => (
        <Text variant="body-sm-bold">{cleanDrId(row.original.drId)}</Text>
    ),
};

const statusCol: ColumnDef<EnrichedCsrdDatapoint> = {
    id: "status",
    header: StatusColHeader,
    cell: ({ row }) => {
        const { status } = row.original;

        const icon = useMemo(() => {
            if (status === CsrdDatapointStatus.InProgress) {
                return "inProgress" as const;
            }
            if (status === CsrdDatapointStatus.NotMaterial) {
                return "notMaterial" as const;
            }
            if (status === CsrdDatapointStatus.NotStarted) {
                return "todo" as const;
            }
            if (status === CsrdDatapointStatus.Validated) {
                return "validated" as const;
            }
            return "inProgress" as const;
        }, [status]);

        return <StatusTagCircular status={icon} size="md" />;
    },
    meta: {
        cellClassName: "flex justify-center",
        headerClassName: "text-center",
    },
};

const descriptionCol: ColumnDef<EnrichedCsrdDatapoint> = {
    id: "description",
    header: i18n.t(`${RESOURCE_NAME}:${keys.table_col_header_description}`),
    meta: {
        cellClassName: "max-w-[300px]",
    },
    cell: ({ row }) => (
        <Ellipsis asChild hasTooltip>
            <Text variant="body-sm">{row.original.name}</Text>
        </Ellipsis>
    ),
};

const valueCol: ColumnDef<EnrichedCsrdDatapoint> = {
    id: "value",
    header: i18n.t(`${RESOURCE_NAME}:${keys.table_col_header_value}`),
    meta: {
        cellClassName: "min-w-[200px] max-w-[300px]",
    },
    cell: ({ row }) => <DatapointValueCell datapoint={row.original} />,
};

const userCol: ColumnDef<EnrichedCsrdDatapoint> = {
    id: "user",
    header: () => {
        const [ownerIds, setOwnerIds] = useAtom(ownerIdsFilterAtom);
        return (
            <UserMultiSelect
                values={ownerIds}
                onValuesChange={setOwnerIds}
                includeNone
            />
        );
    },
    meta: {
        cellClassName: "max-w-fit-content",
    },
    cell: ({ row }) => {
        return (
            <Text variant="body-sm-bold" className="capitalize">
                {row.original.owner
                    ? `${row.original.owner?.firstName} ${row.original.owner?.lastName}`
                    : ""}
            </Text>
        );
    },
};

const actionCol: ColumnDef<EnrichedCsrdDatapoint> = {
    id: "action",
    meta: {
        colWidth: "50px",
    },
    cell: ({
        row: {
            original: { cmsId, esrsId, drId },
        },
    }) => {
        const { pillar: { cmsId: pillarId } = { cmsId: "" } } =
            useGetPillarByEsrsId({
                esrsId,
            });
        return (
            <Link to={`./${pillarId}#dpId="${cmsId}"&drId="${drId}"`}>
                <button className="align-middle text-secondary">
                    <Icon name="arrowRight" />
                </button>
            </Link>
        );
    },
};

export const columns: ColumnDef<EnrichedCsrdDatapoint>[] = [
    esrsCol,
    disclosureRequirementCol,
    statusCol,
    descriptionCol,
    valueCol,
    userCol,
    actionCol,
];
