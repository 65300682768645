import { Icon } from "@design-system/Icon";
import { Calendar } from "@design-system/Inputs/Calendar";
import { Textfield } from "@design-system/Inputs/Textfield";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import {
    Document,
    UpdateDocumentV2Document,
    UpdateVersionDocument,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import FullFrameBlock from "../../../../components/Layouts/FullFrame/Block";
import InnerLayout from "../../../../components/Layouts/FullFrame/Block/InnerLayout";
import TextAreaInput from "../../../../components/inputs_v2/TextArea";
import { useDocument } from "../../hooks";
import "./i18n";

const InfoBlock = ({ Document }: { Document?: Document }) => {
    const { invalidateQuery } = useDocument();
    const { t } = useTranslation("DocumentInfoBlock");
    const [comment, setComment] = useState<string | undefined>(
        Document?.comment || undefined,
    );
    const updateDocumentMutation = useGraphQLMutation(
        UpdateDocumentV2Document,
        {},
        true,
    );
    const updateDocumentVersionMutation = useGraphQLMutation(
        UpdateVersionDocument,
        {},
        true,
    );
    useEffect(() => {
        setComment(Document?.comment || undefined);
    }, [Document?.comment]);

    const onCommentBlur = () => {
        Document &&
            updateDocumentMutation.mutate({
                documentId: Document.id,
                patch: { comment: comment || null },
            });
    };

    const lastVersion =
        Document?.versions &&
        Document?.versions.length > 0 &&
        Document?.versions[0];

    const [expirationDate, setExpirationDate] = useState<string | undefined>(
        (lastVersion &&
            lastVersion.expiresAt &&
            new Date(lastVersion.expiresAt).toISOString().split("T")[0]) ||
            undefined,
    );
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const handleDateChange = (date: Date | undefined) => {
        if (!date) return;
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        setIsPopoverOpen(false);
        setExpirationDate(new Date(formattedDate).toISOString().split("T")[0]);
        lastVersion &&
            updateDocumentVersionMutation.mutate(
                {
                    versionId: lastVersion.id,
                    patch: { expirationDate: formattedDate },
                },
                {
                    onSettled: invalidateQuery,
                },
            );
    };

    return (
        <FullFrameBlock>
            <div className="flex">
                <InnerLayout className="w-1/2 border-r border-tertiary space-y-1">
                    <div className="text-base font-bold">
                        {t("description")}
                    </div>
                    <ReactMarkdown
                        className="markdown text-base font-regular"
                        children={Document?.description || ""}
                        remarkPlugins={[remarkGfm, remarkBreaks]}
                    />
                </InnerLayout>
                <InnerLayout className="w-1/2 border-r border-tertiary space-y-1">
                    <FlexCol className="gap-4">
                        <div>
                            <div className="text-base font-bold">
                                {t("recurrence_title")}
                            </div>
                            <div className="text-base font-regular space-x-1.5 flex items-center ">
                                <Icon
                                    name="arrowRefresh"
                                    size="sm"
                                    className="text-brand"
                                />
                                <span>{t("recurrence")}</span>
                            </div>
                        </div>
                        <div>
                            <Popover
                                open={isPopoverOpen}
                                onOpenChange={setIsPopoverOpen}
                            >
                                <PopoverTrigger asChild>
                                    <Textfield
                                        type="date"
                                        placeholder={t("expiration_date")}
                                        value={expirationDate}
                                    />
                                </PopoverTrigger>
                                <PopoverContent
                                    align="start"
                                    alignOffset={-2}
                                    sideOffset={9}
                                    className="absolute transform -translate-x-3 shadow-lg bg-white"
                                >
                                    <Calendar
                                        mode="single"
                                        defaultMonth={
                                            expirationDate
                                                ? new Date(expirationDate)
                                                : new Date()
                                        }
                                        selected={
                                            expirationDate
                                                ? new Date(expirationDate)
                                                : undefined
                                        }
                                        onSelect={handleDateChange}
                                        className="rounded-md border"
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </FlexCol>
                </InnerLayout>
            </div>
            <InnerLayout>
                <TextAreaInput
                    placeholder={t("comment_placeholder")}
                    text={comment || undefined}
                    setText={setComment}
                    onBlurCallback={onCommentBlur}
                    className="min-h-[80px]"
                />
            </InnerLayout>
        </FullFrameBlock>
    );
};

export default InfoBlock;
