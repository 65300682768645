import {
    ColumnFiltersState,
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import React, { useEffect, useState } from "react";
import { useColumns, useUnrelevantColumns } from "./columns";

import {
    selectedSubthemesAtom,
    selectedTagsAtom,
} from "@app/store/documentStore";
import { useAtom, useAtomValue } from "jotai";
import {
    Document,
    DocumentActivationStatus,
    DocumentType,
    GetDocumentsV2Document,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import { filterUserIdsAtom } from "../../../../components/Filters/MultiFilter/AssignedTo";
import DataTable from "../../../../components/Table/DataTable";
import LoadingTable from "../../../../components/Table/LoadingTable";
import { orderSort } from "../../../../lib/utils";
import {
    DocPageFilterState,
    useDocumentPageState,
} from "../../hooks/pageState";
import ActionBar from "./components/Savebar";

function DocumentsTableInner({
    type,
    documents,
    state,
    setState,
}: {
    type?: DocumentType;
    documents: Document[];
    state: DocPageFilterState;
    setState: (state: Partial<DocPageFilterState>) => void;
}) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = React.useState({});
    const [selecteTagIds] = useAtom(selectedTagsAtom);

    React.useEffect(() => {
        table.getColumn("subtheme")!.setFilterValue(state.subthemeIdsFilter);
    }, [state.subthemeIdsFilter]);

    React.useEffect(() => {
        table.getColumn("theme")!.setFilterValue(state.themeIdFilter);
    }, [state.themeIdFilter]);

    React.useEffect(() => {
        table
            .getColumn("tier")!
            .setFilterValue(state.tier1Filter ? 1 : undefined);
    }, [state.tier1Filter]);

    const filterUserIds = useAtomValue(filterUserIdsAtom);
    React.useEffect(() => {
        table.getColumn("owner")!.setFilterValue(filterUserIds);
    }, [filterUserIds]);

    React.useEffect(() => {
        table.getColumn("status")!.setFilterValue(state.statusFilter);
    }, [state.statusFilter]);

    React.useEffect(() => {
        table
            .getColumn("tags")!
            .setFilterValue(selecteTagIds.length ? selecteTagIds : undefined);
    }, [selecteTagIds]);

    const columns = useColumns(type);

    const table = useReactTable<Document>({
        data: documents,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: (searchFilter) => setState({ searchFilter }),
        onRowSelectionChange: setRowSelection,
        onPaginationChange: (updater) => {
            const nextState =
                typeof updater === "function"
                    ? updater(state.pagination)
                    : updater;
            nextState && setState({ pagination: nextState });
        },
        state: {
            pagination: state.pagination,
            sorting,
            columnFilters,
            rowSelection,
            globalFilter: state.searchFilter,
            columnVisibility: { subtheme: false, theme: false, tier: false },
        },
        autoResetPageIndex: false,
    });

    return (
        <>
            <DataTable
                table={table}
            />
            <ActionBar
                documents={table
                    .getSelectedRowModel()
                    .flatRows.map((r) => r.original)}
                unset={table.resetRowSelection}
            />
        </>
    );
}

export default function DocumentsTable({
    type,
    state,
    setState,
}: {
    type?: DocumentType;
    state: DocPageFilterState;
    setState: (state: Partial<DocPageFilterState>) => void;
}) {
    const { data, isLoading } = useGraphQL(
        GetDocumentsV2Document,
        { type, activationStatus: DocumentActivationStatus.Relevant },
        { enabled: type !== undefined },
        true,
    );
    return !data && isLoading ? (
        <LoadingTable />
    ) : data?.documents ? (
        <DocumentsTableInner
            type={type}
            documents={orderSort(data.documents || []) as Document[]}
            state={state}
            setState={setState}
        />
    ) : (
        <div>Error, refresh ...</div>
    );
}

export const useUnrelevantDocuments = (type?: DocumentType) => {
    const { data } = useGraphQL(
        GetDocumentsV2Document,
        { type, activationStatus: DocumentActivationStatus.NotRelevant },
        { enabled: type !== undefined },
        true,
    );

    return (data?.documents || []) as Document[];
};
export const useUnrelevantDocumentsTable = (
    documents: Document[],
    type: DocumentType,
) => {
    const { state, setState } = useDocumentPageState();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = React.useState({});
    const [selecteSubthemesIds] = useAtom(selectedSubthemesAtom);
    const [pagination, setPagination] = useState({
        pageIndex: 0, //initial page index
        pageSize: 5, //default page size
    });

    const table = useReactTable<Document>({
        data: documents,
        columns: useUnrelevantColumns(type),
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: (unrelevantSearchFilter) =>
            setState({ unrelevantSearchFilter }),
        state: {
            sorting,
            columnFilters,
            rowSelection,
            globalFilter: state.unrelevantSearchFilter,
            pagination,
        },
    });
    useEffect(() => {
        table.getColumn("subtheme") &&
            table
                .getColumn("subtheme")!
                .setFilterValue(
                    selecteSubthemesIds.length
                        ? selecteSubthemesIds
                        : undefined,
                );
    }, [selecteSubthemesIds]);
    return { table };
};
