import { Icon } from "@design-system/Icon";
import { Textfield } from "@design-system/Inputs/Textfield";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import {
    BooleanEnum,
    CsrdDatapointPatch,
    CsrdDatapointStatus,
    ValidationStatus,
} from "@generated/client/graphql";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import { Button } from "@design-system/Inputs/Button";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { Text } from "@design-system/Typography/Text";
import { CsrdDatapoint } from "../../../types";
import {
    isBoolDatapoint,
    isNumDatapoint,
    isStrDatapoint,
} from "../../services";
import "./i18n";
import { UseDatapointStateType } from "./useDatapoint";

export const DatapointValueInput: FC<{
    datapoint: CsrdDatapoint;
    state: UseDatapointStateType;
    handleChange: (patch: CsrdDatapointPatch) => void;
}> = ({ datapoint, state, handleChange }) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const unit = datapoint?.unit?.shortName || "";

    const handleValueBoolChange = (value: BooleanEnum) => {
        state.setValueBool(value);
        handleChange({
            value: {
                boolean:
                    value === "TRUE" ? BooleanEnum.True : BooleanEnum.False,
            },
        });
    };

    const debouncedUpdateValue = useDebouncedStableCallback((value) => {
        if (isNumDatapoint(datapoint) && !isNaN(Number(value))) {
            handleChange({
                value: {
                    number: !!value ? Number(value) : null,
                },
            });
        }
        if (isStrDatapoint(datapoint)) {
            handleChange({ value: { string: value } });
        }
    }, 300);

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNumDatapoint(datapoint) && !isNaN(Number(event.target.value))) {
            state.setValueNumber(
                !!event.target.value ? Number(event.target.value) : undefined,
            );
        }
        if (isStrDatapoint(datapoint)) {
            state.setValueString(event.target.value);
        }
        debouncedUpdateValue(event.target.value);
        if (!event.target.value?.length) {
            state.setValueError(t("required_error"));
        } else {
            state.setValueError(undefined);
        }
    };

    return (
        <FlexRow w="full">
            {state.valueNoData && (
                <FlexCol
                    className="h-[52px] rounded-lg bg-neutral-25 border border-neutral-200"
                    alignItems="start"
                    justifyContent="center"
                    px="3"
                    py="1.5"
                    w="full"
                >
                    <Text variant="body" color="secondary">
                        {t("no_data")}
                    </Text>
                </FlexCol>
            )}

            {!state.valueNoData &&
                (isStrDatapoint(datapoint) || isNumDatapoint(datapoint)) && (
                    <FlexRow w="full" className="relative">
                        <Textfield
                            placeholder={t("value", {
                                unit,
                            })}
                            value={
                                isNumDatapoint(datapoint)
                                    ? state.valueNumber
                                    : state.valueString
                            }
                            onChange={handleValueChange}
                            error={state.valueError}
                            type={isNumDatapoint(datapoint) ? "number" : "text"}
                            disabled={
                                datapoint?.validationStatus ===
                                    ValidationStatus.Validated ||
                                datapoint?.status ===
                                    CsrdDatapointStatus.NotMaterial
                            }
                        />

                        {state.valueIsUpdating !== undefined && (
                            <Icon
                                name={
                                    state.valueIsUpdating
                                        ? "circleLoading"
                                        : "check"
                                }
                                className={cn(
                                    "absolute right-2 top-2",
                                    state.valueIsUpdating
                                        ? "text-secondary"
                                        : "text-beavrGreen",
                                )}
                                size="sm"
                            />
                        )}
                    </FlexRow>
                )}
            {!state.valueNoData && isBoolDatapoint(datapoint) && (
                <SingleSelect
                    value={state.valueBool}
                    onValueChange={handleValueBoolChange}
                    defaultValue={state.valueBool}
                    disabled={
                        datapoint?.validationStatus ===
                            ValidationStatus.Validated ||
                        datapoint?.status === CsrdDatapointStatus.NotMaterial
                    }
                >
                    <SingleSelect.Trigger>
                        <Button
                            variant={"tonal"}
                            className="bg-white h-[52px] py-0 w-full"
                        >
                            <FlexRow
                                justifyContent="between"
                                alignItems="center"
                                w="full"
                                px="3"
                            >
                                <FlexCol alignItems="start">
                                    <Text variant="body-sm" color="secondary">
                                        {t("yes_no")}
                                    </Text>
                                    <Text>
                                        {state.valueBool === BooleanEnum.True
                                            ? t("yes")
                                            : state.valueBool ===
                                                BooleanEnum.False
                                              ? t("no")
                                              : ""}
                                    </Text>
                                </FlexCol>
                                <Icon name="angleDown" />
                            </FlexRow>
                        </Button>
                    </SingleSelect.Trigger>

                    <SingleSelect.Content
                        hasPortal={true}
                        className="w-[200px]"
                    >
                        <SingleSelect.Option
                            key={"TRUE"}
                            value={"TRUE"}
                            label={t("yes")}
                        ></SingleSelect.Option>
                        <SingleSelect.Option
                            key={"FALSE"}
                            value={"FALSE"}
                            label={t("no")}
                        ></SingleSelect.Option>
                    </SingleSelect.Content>
                </SingleSelect>
            )}
        </FlexRow>
    );
};
