import { useSetToast } from "@app/components/Toast";
import { createDatapointGroupAtom } from "@app/store/reportingStore";
import {
    Months,
    Quarters,
    Years,
    months,
    quarters,
    years,
} from "@app/usecases/ReportingUseCases";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    activeDatapointGroupIdsAtom,
    activeReportingEntityIdAtom,
    addPeriodModalIsOpenAtom,
} from "../../context";
import "../../i18n";

export const periodType = {
    Year: "Year",
    Quarter: "Quarter",
    Month: "Month",
} as const;

type PeriodType = keyof typeof periodType;

export default function AddPeriodModal() {
    const { t } = useTranslation("ReportingPage");
    const { setToastSuccess, setToastError } = useSetToast();
    const [open, setOpen] = useAtom(addPeriodModalIsOpenAtom);

    const [{ mutateAsync, isPending }] = useAtom(createDatapointGroupAtom);
    const [activeDatapointGroupsIds, setActiveDatapointGroupsIds] = useAtom<
        string[]
    >(activeDatapointGroupIdsAtom);
    const [activeReportingEntityId] = useAtom(activeReportingEntityIdAtom);

    const [newPeriodType, setNewPeriodType] = useState<PeriodType>(
        periodType.Year,
    );
    const [year, setYear] = useState<Years>(years[2024]);
    const [month, setMonth] = useState<Months | undefined>(undefined);
    const [quarter, setQuarter] = useState<Quarters | undefined>(undefined);

    const onConfirm = async () => {
        if (!activeReportingEntityId) return;
        await mutateAsync(
            {
                entityId: activeReportingEntityId,
                period: { year: Number(year), month, quarter },
            },
            {
                onSuccess: ({ createDatapointGroup: { id } }) => {
                    setActiveDatapointGroupsIds([
                        ...activeDatapointGroupsIds,
                        id,
                    ]);
                    setToastSuccess(t("add_period_success"));
                    setOpen(false);
                },
                onError: () => {
                    setToastError(t("add_period_error"));
                },
            },
        );
    };

    const onPeriodTypeChange = (type: PeriodType) => {
        setNewPeriodType(type);
        if (type === periodType.Month || type === periodType.Year) {
            setQuarter(undefined);
        }
        if (type === periodType.Quarter || type === periodType.Year) {
            setMonth(undefined);
        }
    };
    const onYearChange = (year: Years) => setYear(year);
    const onMonthChange = (month: Months) => setMonth(month);
    const onQuarterChange = (quarter: Quarters) => setQuarter(quarter);

    const disabled = useMemo(() => {
        if (newPeriodType === periodType.Month) {
            return !month;
        }
        if (newPeriodType === periodType.Quarter) {
            return !quarter;
        }
        return false;
    }, [newPeriodType, month, quarter]);

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay>
                <Modal.Content className="w-[460px]">
                    <Modal.Header className="h-[64px]">
                        <Modal.Title>{t("add_period_modal_title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="h-[150px]">
                        <FlexCol gap="2" py="3">
                            <FlexRow gap="2">
                                <Text variant="body-lg" w="40">
                                    {t("period_type")}
                                </Text>
                                <SingleSelect
                                    value={newPeriodType}
                                    defaultValue={periodType.Year}
                                    onValueChange={onPeriodTypeChange}
                                >
                                    <SingleSelect.Trigger />
                                    <SingleSelect.Content>
                                        {Object.values(periodType).map(
                                            (type) => (
                                                <SingleSelect.Option
                                                    key={type}
                                                    value={type}
                                                    label={t(type)}
                                                />
                                            ),
                                        )}
                                    </SingleSelect.Content>
                                </SingleSelect>
                            </FlexRow>
                            <FlexRow gap="2" alignItems="center">
                                <Text variant="body-lg" w="40">
                                    {t("year")} :
                                </Text>
                                <SingleSelect
                                    value={year}
                                    defaultValue={years[2024]}
                                    onValueChange={onYearChange}
                                >
                                    <SingleSelect.Trigger />
                                    <SingleSelect.Content>
                                        {Object.values(years).map((elt) => (
                                            <SingleSelect.Option
                                                key={elt}
                                                value={elt}
                                                label={elt}
                                            />
                                        ))}
                                    </SingleSelect.Content>
                                </SingleSelect>
                            </FlexRow>
                            {newPeriodType === periodType.Month && (
                                <FlexRow gap="2" alignItems="center">
                                    <Text variant="body-lg" w="40">
                                        {t("month")} :
                                    </Text>
                                    <SingleSelect
                                        value={month}
                                        onValueChange={onMonthChange}
                                    >
                                        <SingleSelect.Trigger
                                            placeholder={t("select")}
                                            value={month}
                                        />
                                        <SingleSelect.Content className="h-[240px] overflow-scroll">
                                            {Object.values(months).map(
                                                (elt) => (
                                                    <SingleSelect.Option
                                                        key={elt}
                                                        value={elt}
                                                        className="h-9"
                                                        label={t(elt)}
                                                    />
                                                ),
                                            )}
                                        </SingleSelect.Content>
                                    </SingleSelect>
                                </FlexRow>
                            )}
                            {newPeriodType === periodType.Quarter && (
                                <FlexRow gap="2" alignItems="center">
                                    <Text variant="body-lg" w="40">
                                        {t("quarter")} :
                                    </Text>
                                    <SingleSelect
                                        value={quarter}
                                        onValueChange={onQuarterChange}
                                    >
                                        <SingleSelect.Trigger
                                            placeholder={t("select")}
                                        />

                                        <SingleSelect.Content>
                                            {Object.values(quarters).map(
                                                (elt) => (
                                                    <SingleSelect.Option
                                                        key={elt}
                                                        value={elt}
                                                        label={elt}
                                                    />
                                                ),
                                            )}
                                        </SingleSelect.Content>
                                    </SingleSelect>
                                </FlexRow>
                            )}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t("cancel")}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={isPending}
                            onClick={onConfirm}
                            preventClose
                            disabled={disabled}
                        >
                            {t("add_period_modal_confirm")}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
}
