
import { type FrameworkSection } from "@generated/client/graphql";
import RequirementRow from "./Requirement";
import { activeSubgroupAtom } from "./Navigation";
import { useSetAtom } from "jotai";
import { useOnScrollIntoView } from "@app/shared/utils/scroll";
import { RefObject, useCallback } from "react";

const SectionBlock = ({
    groupName,
    subgroupName,
    section,
    anchor,
    containerAnchorRef,
}: {
    groupName: string;
    subgroupName: string;
    section: FrameworkSection;
    anchor: string;
    containerAnchorRef?: RefObject<HTMLElement>;
}) => {
    const setActiveSubgroup = useSetAtom(activeSubgroupAtom);
    /**
     * `useCallback` is used here to memoize the callback function `cb`.
     * 
     * - `cb` is passed to `useOnScrollIntoView`, which sets up an `IntersectionObserver` that monitors when an element comes into view.
     * - Without `useCallback`, the `cb` function would be recreated on every render, causing `useOnScrollIntoView` to reinitialize the observer
     *   and potentially leading to performance issues or unnecessary observer resets.
     * - By wrapping `cb` in `useCallback`, we ensure that the observer is only set up once.
     */
    const cb = useCallback(() => {
        setActiveSubgroup(anchor);
    }, [anchor, setActiveSubgroup]);
    const onScrollIntoViewRef = useOnScrollIntoView(cb, containerAnchorRef?.current) as React.RefObject<HTMLDivElement>;
    
    return (
        <div id={anchor} data-anchorid={anchor} ref={onScrollIntoViewRef} className="space-y-3 ">
            <div className="px-2">
                <div className="text-sm">
                    {groupName} / {subgroupName}
                </div>
                <div className="font-bold">{section.name}</div>
            </div>
            {!!section.requirements.length && (
                <div className="rounded-lg overflow-hidden border border-secondary">
                    {section.requirements?.map((requirement) => (
                        <RequirementRow requirement={requirement} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default SectionBlock;
