import { useSetToast } from "@app/components/Toast";
import {
    BooleanEnum,
    CsrdDatapointPatch,
    CsrdDatapointStatus,
    ValidationStatus,
} from "@generated/client/graphql";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CsrdDatapoint } from "../../../types";
import { useUpdateDatapoint } from "../../data";
import {
    isBoolDatapoint,
    isNumDatapoint,
    isStrDatapoint,
} from "../../services";
import "./i18n";
import { useAtomValue } from "jotai";
import { activeDatapointIdAtom } from "../../ctx";
import { type PillarDatapoint } from "../../types";

export type UseDatapointStateType = ReturnType<typeof useDatapointState>;

export function useDatapointState() {
    const [status, setStatus] = useState<
        | "todo"
        | "validated"
        | "checked"
        | "loading"
        | "inProgress"
        | "notMaterial"
    >("todo");
    const [ownerId, setownerId] = useState<string | undefined | null>(
        undefined,
    );
    const [validated, setValidated] = useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [commentIsUpdating, setCommentIsUpdating] = useState<
        boolean | undefined
    >(undefined);
    const [valueIsUpdating, setValueIsUpdating] = useState<boolean | undefined>(
        undefined,
    );
    const [charCount, setCharCount] = useState<number>(0);
    const [comment, setComment] = useState<string | undefined>(undefined);
    const [valueBool, setValueBool] = useState<BooleanEnum | undefined>(
        undefined,
    );
    const [valueNoData, setValueNoData] = useState<boolean | undefined>(
        undefined,
    );
    const [valueString, setValueString] = useState<string | undefined>(
        undefined,
    );
    const [valueNumber, setValueNumber] = useState<number | undefined>(
        undefined,
    );
    const [valueError, setValueError] = useState<string | undefined>(undefined);
    const [commentError, setCommentError] = useState<string | undefined>(
        undefined,
    );

    return {
        status,
        ownerId,
        validated,
        isCollapsed,
        commentIsUpdating,
        valueIsUpdating,
        charCount,
        comment,
        valueBool,
        valueString,
        valueNumber,
        valueNoData,
        valueError,
        commentError,
        setownerId,
        setValueBool,
        setValueNumber,
        setValueString,
        setValueNoData,
        setValueError,
        setCommentError,
        setComment,
        setCharCount,
        setIsCollapsed,
        setCommentIsUpdating,
        setValueIsUpdating,
        setStatus,
        setValidated,
    };
}

export function useDatapoint(datapoint: PillarDatapoint) {
    const { mutate } = useUpdateDatapoint(datapoint.id);
    const { setToastSuccess, setToastError } = useSetToast();
    const { t } = useTranslation("FrameworkDatapoint");
    const selectedDatapointId = useAtomValue(activeDatapointIdAtom);
    const datapointState = useDatapointState();
    const {
        setIsCollapsed,
        setStatus,
        setownerId,
        setValidated,
        setCharCount,
        setComment,
        setValueNoData,
        setValueBool,
        setValueString,
        setValueNumber,
        setValueIsUpdating,
        setCommentIsUpdating,
    } = datapointState;

    const handleChange = (patch: CsrdDatapointPatch) => {
        if (!datapoint.id) return;
        const isCommentUpdate = patch.comment !== undefined;
        const isValueUpdate =
            patch?.value?.number !== undefined ||
            patch?.value?.string !== undefined;
        if (isCommentUpdate) {
            setCommentIsUpdating(true);
        }
        if (isValueUpdate) {
            setValueIsUpdating(true);
        }
        mutate(
            {
                id: datapoint.id,
                patch,
            },
            {
                onSuccess: () => {
                    if (isCommentUpdate) {
                        setCommentIsUpdating(false);
                    }
                    if (isValueUpdate) {
                        setValueIsUpdating(false);
                    }
                    if (!isCommentUpdate && !isValueUpdate) {
                        setToastSuccess(t("update_success"));
                    }
                },
                onError: () => {
                    if (!isCommentUpdate && !isValueUpdate) {
                        setToastError(t("update_error"));
                    }
                },
            },
        );
    };

    function initState(datapoint: CsrdDatapoint) {
        setStatus(
            datapoint?.status === CsrdDatapointStatus.Validated
                ? "validated"
                : datapoint?.status === CsrdDatapointStatus.InProgress
                  ? "inProgress"
                  : datapoint?.status === CsrdDatapointStatus.NotMaterial
                    ? "notMaterial"
                    : "todo",
        );
        setownerId(datapoint?.ownerId ?? undefined);
        setValidated(
            datapoint?.validationStatus === ValidationStatus.Validated,
        );

        setCharCount(datapoint?.comment?.length ?? 0);
        setComment(datapoint?.comment ?? undefined);
        setValueNoData(
            datapoint?.value?.noData === BooleanEnum.True ? true : false,
        );
        setValueBool(
            isBoolDatapoint(datapoint)
                ? (datapoint?.value?.boolean as BooleanEnum)
                : undefined,
        );
        setValueString(
            isStrDatapoint(datapoint) ? datapoint?.value?.string : undefined,
        );
        setValueNumber(
            isNumDatapoint(datapoint) ? datapoint?.value?.number : undefined,
        );
    }

    useEffect(() => {
        if (selectedDatapointId === datapoint.cmsId) {
            setIsCollapsed(false);
        }
    }, [selectedDatapointId, datapoint.cmsId]);

    useEffect(() => {
        initState(datapoint);
    }, [datapoint]);

    return { datapointState, handleChange };
}
