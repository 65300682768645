import Section from "@app/screens/Frameworks/framework/components/Section";
import { Gauge } from "@design-system/DataDisplay/Gauge";
import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { CsrdEsrs, CsrdPillar } from "@generated/client/graphql";
import chroma from "chroma-js";
import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import { useCsrdOverview } from "../../data";

export const ESRSRow: FC<{ esrs: CsrdEsrs; pillarCmsId: string }> = ({
    esrs,
    pillarCmsId,
}) => {
    const { esrsDisplayStats } = useCsrdOverview();
    const navigate = useNavigate();

    const handleRowClick = () => {
        navigate(`../${pillarCmsId}`);
    };
    return (
        <FlexRow
            key={esrs.name}
            h="10"
            alignItems="center"
            alignSelf="stretch"
            className="cursor-pointer hover:bg-secondary [&:not(:last-child)]:border-b border-tertiary"
            onClick={handleRowClick}
        >
            <FlexRow
                px="4"
                py="3"
                alignItems="center"
                justifyContent="between"
                className="flex-1 group"
            >
                <Text variant="body-sm-bold">{esrs.name}</Text>
                <FlexRow justifyContent="end" alignItems="center" gap="6">
                    <Text variant="body-sm">
                        {esrsDisplayStats[esrs.name]?.validRatio}
                    </Text>
                    <Gauge className="w-[320px]">
                        <Gauge.Label>
                            <Text variant="body-sm-bold">
                                {esrsDisplayStats[esrs.name].validPercentage}
                            </Text>
                        </Gauge.Label>
                        <Gauge.List>
                            <Gauge.Item
                                value={esrs.stats.validated}
                                color={chroma("#03A365")}
                                label={esrsDisplayStats[esrs.name].validLabel}
                            ></Gauge.Item>
                            <Gauge.Item
                                value={esrs.stats.inProgress}
                                color={chroma("#5E89AE")}
                                label={
                                    esrsDisplayStats[esrs.name].inProgressLabel
                                }
                            ></Gauge.Item>
                            <Gauge.Item
                                value={esrs.stats.notStarted}
                                color={chroma("#89B3D0")}
                                label={
                                    esrsDisplayStats[esrs.name].notStartedLabel
                                }
                            ></Gauge.Item>
                            <Gauge.Item
                                value={esrs.stats.notMaterial}
                                color={chroma("#E6E6E6")}
                                label={
                                    esrsDisplayStats[esrs.name].notMaterialLabel
                                }
                            ></Gauge.Item>
                        </Gauge.List>
                    </Gauge>
                    <Icon
                        name="angleRight"
                        className="text-secondary group-hover:text-beavrGreen"
                    ></Icon>
                </FlexRow>
            </FlexRow>
        </FlexRow>
    );
};
export const CsrdPillarSection: FC<{ pillar: CsrdPillar; idx: number }> = ({
    pillar,
    idx,
}) => {
    const { pillarsValidationStats } = useCsrdOverview();

    return (
        <Section className="w-full" key={pillar.cmsId}>
            <Section.Block>
                <Section.BlockHeader>
                    <FlexRow
                        p="1"
                        justifyContent="between"
                        alignItems="center"
                        alignSelf="stretch"
                    >
                        <Text variant="body-lg-bold">{pillar.name}</Text>
                        <Text variant="body-sm">
                            {`${pillarsValidationStats?.[idx]}% validated`}
                        </Text>
                    </FlexRow>
                </Section.BlockHeader>
                <Section.BlockBody>
                    {pillar?.esrss.map((esrs) => (
                        <ESRSRow esrs={esrs} pillarCmsId={pillar?.cmsId} />
                    ))}
                </Section.BlockBody>
            </Section.Block>
        </Section>
    );
};

export const OverviewTab = () => {
    const { pillars } = useCsrdOverview();

    return (
        <FlexCol gap="5" alignItems="start" className="flex-1">
            {pillars?.map((pillar, idx) => (
                <CsrdPillarSection pillar={pillar as CsrdPillar} idx={idx} />
            ))}
        </FlexCol>
    );
};
