// React Hook
import { useEntities } from "@app/store/entityStore";
import {
    humanReadablePeriodToPeriod,
    periodToHumanReadablePeriod,
} from "@app/utils/periodToUrl";
import { useAtom, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
    activeDatapointGroupIdsAtom,
    activeReportingEntityIdAtom,
} from "./context";
import { useDatapointGroups } from "./data";

export function useSetReportingFilters() {
    let [searchParams, setSearchParams] = useSearchParams();

    const urlPeriods = searchParams.get("periods")?.split(",");
    const [periodsDidLoad, setPeriodsDidLoad] = useState<boolean>(false);

    const urlSite = searchParams.get("site");
    const [siteDidLoad, setSiteDidLoad] = useState<boolean>(false);

    const groups = useDatapointGroups();
    const setActiveDatapointGroupsIds = useSetAtom(activeDatapointGroupIdsAtom);

    const [activeReportingEntityId, setActiveReportingEntityId] = useAtom(
        activeReportingEntityIdAtom,
    );
    const { entities } = useEntities();

    useEffect(() => {
        if (!urlPeriods) {
            setPeriodsDidLoad(true);
            return;
        }
        if (siteDidLoad || !groups || !urlPeriods || !activeReportingEntityId) {
            return;
        }
        const parsedUrlPeriods = humanReadablePeriodToPeriod(urlPeriods);
        const activeIds = groups
            .filter((group) => {
                return parsedUrlPeriods.some(
                    (period) =>
                        activeReportingEntityId === group.entity?.id &&
                        period.year === group.period.year &&
                        period.quarter === group.period.quarter &&
                        period.month === group.period.month,
                );
            })
            .map((group) => group.id);

        setActiveDatapointGroupsIds(activeIds);
        setPeriodsDidLoad(true);
    }, [urlPeriods, groups]);

    useEffect(() => {
        if (!urlSite) {
            setSiteDidLoad(true);
            return;
        }
        if (periodsDidLoad || !entities) {
            return;
        }

        const entity = entities.find((entity) => entity.name === urlSite);
        if (entity) {
            setActiveReportingEntityId(entity.id);
        }
        setSiteDidLoad(true);
    }, [urlSite, entities]);

    // Set the params
    const setFilters = useCallback(
        (filters: {
            activeDatapointGroupsIds?: string[];
            activeReportingEntityId?: string;
        }) => {
            if (filters.activeDatapointGroupsIds) {
                const selectedGroups = groups?.filter(
                    (group) =>
                        filters.activeDatapointGroupsIds!.includes(group.id) &&
                        group.entity?.id === activeReportingEntityId,
                );

                const urlPeriods = selectedGroups
                    ?.map((p) => p.period)
                    .map(periodToHumanReadablePeriod);
                setSearchParams((searchParams) => {
                    !!urlPeriods?.length
                        ? searchParams.set("periods", urlPeriods.join(","))
                        : searchParams.delete("periods");

                    return searchParams.toString();
                });

                setActiveDatapointGroupsIds(filters.activeDatapointGroupsIds);
            }
            if (filters.activeReportingEntityId) {
                const entity = entities?.find(
                    (entity) => entity.id === filters.activeReportingEntityId,
                );
                if (entity) {
                    setSearchParams((searchParams) => {
                        searchParams.set("site", entity.name);
                        return searchParams.toString();
                    });
                    setActiveReportingEntityId(activeReportingEntityId);
                }
            }
        },
        [groups, setActiveDatapointGroupsIds, activeReportingEntityId],
    );

    return {
        setFilters,
    };
}
