import {
    createContext,
    forwardRef,
    useContext,
    type ButtonHTMLAttributes,
    type FC,
    type ForwardRefExoticComponent,
    type PropsWithChildren,
    type Ref,
} from "react";

import { Icon, IconName } from "@design-system/Icon";

import { cn, type ClassValue } from "../../Utilities";

type NavigationContext = {
    selectedId?: string;
    onSelectedIdChange: (id: string) => void;
};

export const NavigationContext = createContext<NavigationContext | null>(null);

const Header: FC<PropsWithChildren> = ({ children }) => {
    return (
        <header
            className={cn(
                "flex items-center h-[64px] py-3 px-4 border-b border-tertiary ",
            )}
        >
            {children}
        </header>
    );
};

const Content: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div
            className={cn(
                "flex flex-col flex-1 overflow-y-auto  scrollbar-hide p-4",
            )}
        >
            {children}
        </div>
    );
};

const GroupTitle: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={cn("mt-1 py-1.5 px-2 text-sm text-tertiary")}>
            {children}
        </div>
    );
};

export type NavigationButtonComponentType = ForwardRefExoticComponent<
    PropsWithChildren<
        NavigationButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
    >
>;

const NavigationButton: NavigationButtonComponentType = forwardRef(
    (
        { children, classNames, id, iconName, text, ...buttonProps },
        ref: Ref<HTMLButtonElement>,
    ) => {
        const ctx = useContext(NavigationContext);
        // create a context to know if button is focused/hovered or active

        if (!ctx) throw new Error("Context must not null");
        const { selectedId, onSelectedIdChange } = ctx;
        const isSelected = id === selectedId;

        const handleClick = () => {
            onSelectedIdChange(id);
        };
        return (
            <button
                ref={ref}
                className={cn(
                    "py-1.5 px-1 flex items-center space-x-3 cursor-pointer whitespace-nowrap font-bold",
                    isSelected
                        ? "text-white bg-green rounded-lg"
                        : "text-secondary hover:text-green",
                    classNames,
                )}
                onClick={handleClick}
                {...buttonProps}
            >
                <span className="p-0.5">
                    <Icon name={iconName} size="sm" />
                </span>
                <span>{text}</span>
            </button>
        );
    },
);

export type NavigationComponentType = FC<
    PropsWithChildren<{
        selectedId?: string;
        onSelectedIdChange: (id: string) => void;
        className?: ClassValue;
    }>
> & {
    Header: typeof Header;
    Content: typeof Content;
    GroupTitle: typeof GroupTitle;
    Button: typeof NavigationButton;
};

type NavigationButtonProps = {
    classNames?: string;
    id: string;
    text: string;
    iconName: IconName;
};

export const Navigation: NavigationComponentType = ({
    children,
    className,
    selectedId,
    onSelectedIdChange,
}) => {
    return (
        <NavigationContext.Provider value={{ selectedId, onSelectedIdChange }}>
            <nav
                className={cn(
                    "min-w-0 w-[232px] h-screen shrink-0 flex flex-col bg-primary border-r border-tertiary",
                    className,
                )}
            >
                {children}
            </nav>
        </NavigationContext.Provider>
    );
};

Navigation.Header = Header;
Navigation.Content = Content;
Navigation.GroupTitle = GroupTitle;
Navigation.Button = NavigationButton;
