import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { S3File } from "@generated/client/graphql";
import * as Popover from "@radix-ui/react-popover";
import { FC, PropsWithChildren, useContext } from "react";
import { InputContext } from ".";
import CommentBox, { CommentBoxProps } from "./CommentBox";

const CommentPopover: FC<
    PropsWithChildren<CommentBoxProps> & {
        commentTriggerTextPlaceholder: string;
        noDataTriggerTextPlaceholder: string;
        proofs: S3File[] | undefined;
    }
> = ({ children, ...props }) => {
    const ctx = useContext(InputContext);
    if (!ctx) throw new Error("Context must not null");

    const handleCommentIconClick = () => {
        ctx.setPopoverOpen(true);
    };

    return (
        <Popover.Root open={ctx.popoverOpen} onOpenChange={ctx.setPopoverOpen}>
            <Popover.Trigger asChild className="block">
                <Popover.Root open={ctx.focuswithin}>
                    <Popover.Anchor className="float-right" />
                    <Popover.Portal>
                        <Popover.Content
                            asChild
                            align="start"
                            onOpenAutoFocus={(event: Event) =>
                                event.preventDefault()
                            }
                        >
                            <button
                                className="absolute right-0 -bottom-[94px]"
                                onClick={handleCommentIconClick}
                            >
                                <FlexRow
                                    px="3"
                                    gap="2"
                                    alignItems="center"
                                    className="bg-warning-600 rounded-b"
                                >
                                    <Text
                                        variant="body-sm-bold"
                                        className="text-nowrap"
                                    >
                                        {props.checked
                                            ? props.noDataTriggerTextPlaceholder
                                            : props.commentTriggerTextPlaceholder}
                                    </Text>
                                    <Icon
                                        name={
                                            props.checked
                                                ? "validate"
                                                : "comment"
                                        }
                                        className="w-3"
                                    ></Icon>
                                </FlexRow>
                            </button>
                        </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </Popover.Trigger>

            <Popover.Anchor />
            <Popover.Portal>
                <Popover.Content align="start">
                    <CommentBox
                        comment={props.comment}
                        proofs={props.proofs}
                        onCommentChange={props.onCommentChange}
                        onCommentBlur={props.onCommentBlur}
                        setOpen={ctx.setPopoverOpen}
                        checked={props.checked}
                        onChecked={props.onChecked}
                        checkTextPlaceholder={props.checkTextPlaceholder}
                    />
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

export default CommentPopover;
