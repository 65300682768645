import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import {
    Document,
    DocumentExpirationStatus,
    DocumentType,
    GetDocumentsV2Document,
    TagType,
    UpdateDocumentV2Document,
} from "../../../../../generated/client/graphql";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import UserSelect from "../../../../components/Dropdown/dropdowns/User";
import AssignedToFilter from "../../../../components/Filters/MultiFilter/AssignedTo";
import Header from "../../../../components/Table/Header";

import { Variant } from "../../../../components/Tags/StatusTag";
import Status from "../../../../components/Tags/StatusTag/Status";

import { useQueryClient } from "@tanstack/react-query";
import { Download, Pencil, Rocket } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import SubthemeMultiSelect from "@app/shared/components/SubthemeMultiSelect";
import TagListEditor from "@app/shared/components/TagListEditor";
import TagsMultiSelect from "@app/shared/components/TagsMultiSelect";
import {
    searchThemesFilterAtom,
    selectedSubthemesAtom,
    selectedTagsAtom,
    updateDocumentMutation,
} from "@app/store/documentStore";
import { FlexCol } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { useAtom } from "jotai";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import Checkbox from "../../../../components/Checkbox";
import DocumentExpirationStatusFilter from "../../../../components/Dropdown/dropdowns/ExpirationStatus/filter";
import { Tooltip } from "../../../../components/Tooltip";
import { cn } from "../../../../lib/utils";
import { useDocumentPageState } from "../../hooks/pageState";
import "./i18n";
import { ExtensionTag } from "@app/shared/components/ExtensionTag";
import { ColorTag } from "@design-system/DataDisplay/Tag/ColorTag";
import { getFileFormatCategory } from "@app/usecases/VersionUseCases/utils";

const selectCol: ColumnDef<Document> = {
    id: "select",
    meta: {
        headerClassName: "w-[34px]",
        colWidth: "5%",
    },
    header: ({ table }) => {
        return (
            <div className="flex flex-row space-x-2">
                <Checkbox
                    checked={table.getIsAllRowsSelected()}
                    onClick={(e) => {
                        e.stopPropagation();
                        table.toggleAllRowsSelected(
                            !table.getIsAllRowsSelected(),
                        );
                    }}
                    aria-label="Select all"
                />
            </div>
        );
    },
    cell: ({ row }) => {
        return (
            <div className="flex flex-row space-x-2">
                <Checkbox
                    checked={row.getIsSelected()}
                    onClick={(e) => {
                        e.stopPropagation();
                        row.toggleSelected();
                    }}
                    aria-label="Select"
                />
            </div>
        );
    },
};

const nameCol = (active: boolean): ColumnDef<Document> => {
    return {
        id: "name",
        accessorKey: "name",
        meta: {
            colWidth: active ? "45%" : "80%",
        },
        header: ({ column }) => {
            const { t } = useTranslation("Documents2Table");
            const { state, setState } = useDocumentPageState();
            return (
                <div className="flex items-center gap-2">
                    <Header title={t("document")} column={column} />
                    <Tooltip tooltipContent={t("rocket_header_tooltip")}>
                        <button
                            className="flex items-center"
                            onClick={() => {
                                setState({ tier1Filter: !state.tier1Filter });
                            }}
                        >
                            <Rocket
                                className={cn(
                                    "w-4 h-4",
                                    state.tier1Filter && " text-green ",
                                )}
                            />
                        </button>
                    </Tooltip>
                </div>
            );
        },
        cell: ({ row }) => {
            const { t } = useTranslation("Documents2Table");
            const fileExtension = row.original.lastVersion?.finalFile?.extension
            return (
                <FlexCol className="group">
                    <Link 
                        to={`${window.location.pathname}/${row.original.uniqueUrl}`}
                        style={{ color: 'inherit', textDecoration: 'none'}}
                        className="flex flex-col gap-1 items-start justify-center max-w-full overflow-hidden">
                        <div className="flex items-center gap-2 overflow-hidden max-w-full group-hover:underline">
                            <Ellipsis asChild hasTooltip>
                                <div className="text-sm font-bold text-primary">
                                    {row.original.name}
                                </div>
                            </Ellipsis>
                            {row.original.tier === 1 && (
                                <Tooltip
                                    tooltipContent={t("text_tier_1")}
                                    className="max-w-[500px] whitespace-pre-line"
                                    side="top"
                                >
                                    <div className="flex">
                                        <Rocket className="w-[14px] h-[14px]" />
                                    </div>
                                </Tooltip>
                            )}
                            {fileExtension ? (
                                <ExtensionTag name={getFileFormatCategory(fileExtension)}/>
                            )
                            : null}
                        </div>
                        {row.original.type === DocumentType.MasterPolicy && (
                            <ColorTag color="#03A365" backgroundColor="#EEFFF2" borderColor="#03A365" size="xs" text={t("master_policy")} className="py-0 px-1"/>
                        )}
                        {row.original.type === DocumentType.Policy && (
                            <ColorTag color={"#A0278C"} size="xs" text={t("policy")} className="py-0 px-1"/>
                        )}
                    </Link>
                </FlexCol>
            );
        },
    };
};

const tagsCol = (documentType?: DocumentType): ColumnDef<Document> => ({
    id: "tags",
    meta: {
        colWidth: "150px",
    },
    filterFn: (row, _, filterValue) => {
        const tagIds = row.original.tags?.map((tag) => tag.id);
        return (
            filterValue.filter((elt: string) => tagIds.includes(elt)).length > 0
        );
    },

    header: () => {
        const [selectedTagsIds, setSelectedTagsIds] = useAtom(selectedTagsAtom);

        // Reset selected tags when document type changes
        // because this values from an atom and changing
        // the document type will not trigger a re-render
        useEffect(() => {
            setSelectedTagsIds([]);
        }, [documentType]);
        return (
            <TagsMultiSelect
                tagsIds={selectedTagsIds}
                setTagsIds={setSelectedTagsIds}
                type={TagType.Document}
            />
        );
    },
    cell: ({ row }) => {
        const [{ mutate }] = useAtom(updateDocumentMutation);
        const [selectedTagIds, setSelectedTagsIds] = useState<string[]>(
            row.original.tags?.map((tag) => tag.id) || [],
        );
        const updateDocumentTags = (tagIds: string[]) => {
            setSelectedTagsIds(tagIds);
            mutate({
                ids: [row.original.id],
                patch: {
                    tagIds,
                },
            });
        };

        return (
            <TagListEditor
                values={selectedTagIds}
                onValuesChange={updateDocumentTags}
                tagType={TagType.Document}
            />
        );
    },
});

const subThemeCol: ColumnDef<Document> = {
    id: "subtheme",
    accessorKey: "subtheme.id",
    filterFn: "arrIncludesSome",
    meta: {
        colWidth: "30%",
    },
    header: () => {
        const [selectedSubthemesIds, setSelectedSubthemesIds] = useAtom(
            selectedSubthemesAtom,
        );
        const [searchFilter, setSearchFilter] = useAtom(searchThemesFilterAtom);
        return (
            <SubthemeMultiSelect
                values={selectedSubthemesIds}
                onValuesChange={setSelectedSubthemesIds}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
            />
        );
    },
    cell: ({ row }) => {
        return (
            <ColorTag
                color={row.original.subtheme?.theme?.color || "green"}
                text={row.original.subtheme?.name || ""}
            />
        );
    },
};

export const useColumns = (type?: DocumentType): ColumnDef<Document>[] => {
    const columns = useMemo(() => {
        return [
            selectCol,
            nameCol(true),
            {
                id: "tier",
                filterFn: "equals",
                accessorKey: "tier",
            },
            {
                id: "subtheme",
                filterFn: "arrIncludesSome",
                accessorKey: "subtheme.id",
            },
            {
                id: "theme",
                filterFn: "equals",
                accessorKey: "subtheme.themeId",
            },
            tagsCol(type),
            {
                id: "owner",
                filterFn: "arrIncludesSome",
                accessorKey: "owner.id",
                meta: {
                    colWidth: "20%",
                },
                header: () => <AssignedToFilter />,
                cell: ({ row }) => {
                    const updateDocumentMutation = useGraphQLMutation(
                        UpdateDocumentV2Document,
                        {},
                        true,
                    );
                    const client = useQueryClient();
                    return (
                        <div className="flex space-x-2 items-center">
                            <UserSelect
                                onChange={(id) => {
                                    updateDocumentMutation.mutate(
                                        {
                                            documentId: row.original.id,
                                            patch: { assignedToId: id || null },
                                        },
                                        {
                                            onSuccess: () =>
                                                client.invalidateQueries({
                                                    queryKey: useGraphQL.getKey(
                                                        GetDocumentsV2Document,
                                                        { type: type },
                                                    ),
                                                }),
                                        },
                                    );
                                }}
                                selectedUserId={row.original.owner?.id}
                            />
                        </div>
                    );
                },
            },
            {
                id: "status",
                filterFn: "arrIncludesSome",
                accessorKey: "status",
                meta: {
                    colWidth: "15%",
                },
                header: () => {
                    const { state, setState } = useDocumentPageState();
                    return (
                        <DocumentExpirationStatusFilter
                            status={state.statusFilter}
                            setStatus={(s) => setState({ statusFilter: s })}
                        />
                    );
                },
                cell: ({ row }) => {
                    const { t } = useTranslation("Documents2Table");

                    return row.original.lastVersion?.expirationStatus ===
                        DocumentExpirationStatus.Ok ? (
                        <Status variant={Variant.Done}>{t("done")}</Status>
                    ) : row.original.lastVersion?.expirationStatus ===
                      DocumentExpirationStatus.Draft ? (
                        <Status variant={Variant.InProgress}>
                            {t("draft")}
                        </Status>
                    ) : row.original.lastVersion?.expirationStatus ===
                      DocumentExpirationStatus.ReadyForApproval ? (
                        <Status variant={Variant.ArrowDown}>
                            {t("ready_for_approval")}
                        </Status>
                    ) : row.original.lastVersion?.expirationStatus ===
                      DocumentExpirationStatus.PendingBeavrReview ? (
                        <Status variant={Variant.PendingReview}>
                            {t("pending_bv_review")}
                        </Status>
                    ) : row.original.lastVersion?.expirationStatus ===
                      DocumentExpirationStatus.Rejected ? (
                        <Status variant={Variant.Nothing}>
                            {t("rejected")}
                        </Status>
                    ) : !row.original.lastVersion ? (
                        <div className="text-sm text-tertiary">
                            {t("not_started")}
                        </div>
                    ) : (
                        <>{JSON.stringify(row.original.lastVersion.status)}</>
                    );
                },
            },
            {
                id: "actions",
                meta: {
                    colWidth: "15%",
                },
                cell: ({ row }) => {
                    const { t } = useTranslation("Documents2Table");
                    const navigate = useNavigate();
                    return (
                        <div className="flex space-x-2 items-center justify-end">
                            {row.original.lastVersion === null ? (
                                <ButtonSmall
                                    onClick={() => {
                                        navigate(
                                            `${row.original.uniqueUrl}`,
                                        );
                                    }}
                                >{t("start")}</ButtonSmall>
                            ) : row.original.lastVersion?.expirationStatus ===
                              DocumentExpirationStatus.Draft ? (
                                <ButtonSmall
                                    IconLeft={Pencil}
                                    variant={ButtonType.TONAL}
                                    onClick={(e) => {
                                        navigate(
                                            `${row.original.uniqueUrl}/edit`,
                                        );
                                        e.stopPropagation();
                                    }}
                                />
                            ) : row.original.lastVersion?.finalFile
                                  ?.signedUrl ? (
                                <ButtonSmall
                                    IconLeft={Download}
                                    variant={ButtonType.TONAL}
                                    onClick={(e) => {
                                        const signedUrl =
                                            row.original.lastVersion?.finalFile
                                                ?.signedUrl;
                                        signedUrl &&
                                            window
                                                .open(signedUrl, "_blank")
                                                ?.focus();

                                        e.stopPropagation();
                                    }}
                                />
                            ) : (
                                <div>
                                    {
                                        row.original.lastVersion?.finalFile
                                            ?.signedUrl
                                    }
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ] as ColumnDef<Document>[];
    }, [type]);

    return columns;
};

export const useUnrelevantColumns = (
    type?: DocumentType,
): ColumnDef<Document>[] => {
    return [
        selectCol,
        nameCol(false),
        ...(type === DocumentType.CustomDocument ? [] : [subThemeCol]),
    ] as ColumnDef<Document>[];
};
