import * as React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import BodyHeader from "../../shared/components/BodyHeader";

import { ButtonType } from "../../components/Button/ButtonShared";

import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { Eye, FerrisWheel, MoreHorizontal, Scale, Trophy } from "lucide-react";
import { DocumentType } from "../../../generated/client/graphql";
import ButtonLarge from "../../components/Button/ButtonLarge";
import { DropdownTrigger } from "../../components/Dropdown/components";
import BaseItem from "../../components/Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../components/Dropdown/components/dropdowns/layout";
import SearchFilter from "../../components/Filters/Search";
import ThemeTabFilter from "../../components/Filters/ThemeTabFilter";
import { useCapturePageView } from "../../lib/tracking/utils";
import "../Document/components/UpdateDocumentModal/i18n";
import AddDocumentModal from "./components/AddDocumentModal";
import DocumentsTable, {
    useUnrelevantDocuments,
} from "./components/DocumentsTable";
import UnrelevantDocumentsModal from "./components/UnrelevantDocumentsModal";
import { useDocumentPageState } from "./hooks/pageState";
import "./i18n";
import { usePageviewTrack } from "@app/shared/planhat";

export default function Documents2({ type }: { type?: DocumentType }) {
    const { state, setState } = useDocumentPageState();

    useCapturePageView(
        type === DocumentType.Policy
            ? "policies"
            : type === DocumentType.Procedure
              ? "measures"
              : "other-docs",
    );

    const { t } = useTranslation(`Documents`);
    const [open, setOpen] = React.useState(false);

    const dataChameleon = {
        key:
            type === DocumentType.Policy
                ? "policies"
                : type === DocumentType.Procedure
                  ? "measures"
                  : "custom-documents",
        type:
            type && [DocumentType.Policy, DocumentType.Procedure].includes(type)
                ? "tutorial"
                : "help",
    } as const;

    const [openUnrelevantDocumentsModal, setOpenUnrelevantDocumentsModal] =
        React.useState(false);
    const unrelevantDocuments = useUnrelevantDocuments(type);

    usePageviewTrack(
        type === DocumentType.Policy
            ? "pageview_policies"
            : type === DocumentType.Procedure
              ? "pageview_procedures"
              : "pageview_custom_docs",
    );
    return (
        <Container>
            <BodyHeader
                dataChameleon={dataChameleon}
                icon={
                    type === DocumentType.Policy
                        ? Scale
                        : type === DocumentType.Procedure
                          ? Trophy
                          : FerrisWheel
                }
                title={t(`${type}_title`)}
                subTitle={t(`${type}_subtitle`)}
                action={
                    <>
                        {type === DocumentType.CustomDocument && (
                            <div className="flex space-x-2">
                                <ButtonLarge
                                    text={t("add_document")}
                                    variant={ButtonType.FLAT_PRIMARY}
                                    onClick={() => setOpen(true)}
                                />
                            </div>
                        )}
                        <div className="flex space-x-2 items-center justify-end">
                            <DropdownMenu modal={false}>
                                <DropdownTrigger asChild>
                                    <ButtonLarge
                                        variant={ButtonType.OUTLINED}
                                        IconLeft={MoreHorizontal}
                                    />
                                </DropdownTrigger>
                                <DropdownContentLayout
                                    align="end"
                                    side="bottom"
                                    sideOffset={0}
                                >
                                    <BaseItem
                                        onClick={() =>
                                            setOpenUnrelevantDocumentsModal(
                                                true,
                                            )
                                        }
                                        IconLeft={() => (
                                            <Eye className="text-green h-4 w-4" />
                                        )}
                                    >
                                        {t("see_not_relevant", {
                                            count: unrelevantDocuments.length,
                                        })}
                                    </BaseItem>
                                </DropdownContentLayout>
                            </DropdownMenu>
                        </div>
                    </>
                }
            />
            <div className="p-4 space-y-4">
                <div className="flex space-x-4 justify-between">
                    <ThemeTabFilter
                        themeId={state.themeIdFilter}
                        setThemeId={(id) => setState({ themeIdFilter: id })}
                        subthemeIds={state.subthemeIdsFilter}
                        setSubthemeIds={(ids) =>
                            setState({ subthemeIdsFilter: ids })
                        }
                    />
                    <SearchFilter
                        searchString={state.searchFilter}
                        setSearchString={(searchFilter) =>
                            setState({ searchFilter })
                        }
                        className="w-[225px]"
                    />
                </div>
                <DocumentsTable type={type} state={state} setState={setState} />
            </div>
            {unrelevantDocuments && (
                <UnrelevantDocumentsModal
                    documents={unrelevantDocuments}
                    type={type || DocumentType.CustomDocument}
                    open={openUnrelevantDocumentsModal}
                    setOpen={setOpenUnrelevantDocumentsModal}
                />
            )}
            <AddDocumentModal type={type} open={open} setOpen={setOpen} />
        </Container>
    );
}
