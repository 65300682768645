import { useCallback, useEffect, useRef } from "react";

/**
 * Hook that returns a callback function to be used as a ref.
 * When the ref is attached to an element, it checks the `shouldScroll` condition.
 * If `shouldScroll` is true, it scrolls into the view of the element smoothly.
 * The scroll is activated not only when the element mounts, but also whenever the `shouldScroll` condition changes to true.
 *
 * @param {boolean} [shouldScroll] - Condition to determine whether to scroll to the element or not.
 * @returns {Function} Callback function to be used as a ref.
 */
export function useConditionalScrollTo(shouldScroll?: boolean) {
    const scrollTo = useCallback(
        (element: HTMLElement | null) => {
            if (shouldScroll && element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        },
        [shouldScroll],
    );

    return scrollTo;
}

/**
 * This hook sets up an IntersectionObserver to detect when an element (referenced by the returned `ref`) scrolls into view.
 * The `cb` callback will be invoked when the element becomes visible within the viewport or a specified root element.
 * 
 * The `root` option specifies the element that serves as the viewport for checking visibility. 
 * - If `root` is null (default), it uses the browser's viewport.
 * 
 * The `rootMargin` option is a set of offsets to shrink or expand the root's bounding box before it intersects with the target element. 
 * - The format is a string with values for top, right, bottom, and left (e.g., "0px 0px 0px 0px").
 * - In this case, `rootMargin: "0px 0px -100% 0px"` means that the callback will trigger as soon as the element intersects with
 *   the top of the root viewport.
 * 
 * The `threshold` option defines the percentage of the element's visibility required to trigger the callback. 
 * - A value of 0 means that the callback will be triggered as soon as any part of the element enters the root's viewport.
 * - A value of 1 requires the entire element to be visible before the callback is fired.
 * 
 * The hook returns a `ref`, which you can assign to the element you want to observe for visibility changes.
 */
export function useOnScrollIntoView<T extends HTMLElement>(
    cb: () => void,
    root: T | null = null,
) {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        const element = ref.current;
        if (!element) return;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        cb();
                    }
                });
            },
            {
                root: root,
                rootMargin: "0px 0px -100% 0px",
                threshold: 0,
            },
        );

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, [cb, root, ref]);

    return ref;
}
