import { useSetToast } from "@app/components/Toast";
import { GraphQLDatapointGroupRepository } from "@app/repositories/GraphQLRepositories/DatapointGroupRepository";
import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import { CreateIndicatorInput } from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const reportingKeys = {
    all: ["reporting"] as const,
    periods: () => [...reportingKeys.all, "periods"] as const,
};

const indicatorRepository = new GraphQLIndicatorRepository();
const datapointGroupRepository = new GraphQLDatapointGroupRepository();
export const useCreateCustomIndicators = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation("ReportingPage");
    const { setToastSuccess, setToastError } = useSetToast();

    return useMutation({
        mutationFn: async (inputs: CreateIndicatorInput[]) => {
            return indicatorRepository.createManyCustomIndicators(inputs);
        },
        onSettled() {
            queryClient.invalidateQueries({ queryKey: reportingKeys.all });
        },
        onSuccess(data) {
            setToastSuccess(
                t("create_indicators_success", {
                    count: data.createIndicators,
                }),
            );
        },
        onError() {
            setToastError(t("create_indicators_error"));
        },
    });
};

export const useDatapointGroups = () => {
    const { data } = useQuery({
        queryKey: reportingKeys.periods(),
        queryFn: datapointGroupRepository.get,
    });

    return data?.datapointGroups;
};
