import { queryClient } from "@app/QueryClientWithHeaders";
import {
    activeDatapointGroupIdsAtom,
    activeReportingEntityIdAtom,
} from "@app/pages/Reporting/context";
import { GraphQLDatapointGroupRepository } from "@app/repositories/GraphQLRepositories/DatapointGroupRepository";
import { GraphQLDatapointRepository } from "@app/repositories/GraphQLRepositories/DatapointRepository";
import { GraphQLUserRepository } from "@app/repositories/GraphQLRepositories/UserRepository";
import { GraphQLIndicatorEntityConfigRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorEntityConfigRepository";
import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import {
    exportReporting,
    exportReportingFiles,
    filterContributors,
    filterIndicators,
} from "@app/usecases/ReportingUseCases";
import {
    CreateDatapointGroupInput,
    DatapointUpsertInput,
    IndicatorEntityConfigPatch,
    IndicatorPatch,
    ReportingExportInput,
    ReportingRemindersInput,
    User,
} from "generated/client/graphql";
import { atom } from "jotai";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";
import { atomWithStorage } from "jotai/utils";
import { reportingKeys } from "./queryKeys";

const indicatorRepository = new GraphQLIndicatorRepository();
const datapointGroupRepository = new GraphQLDatapointGroupRepository();
const datapointRepository = new GraphQLDatapointRepository();
const indicatorEntityConfigRepository =
    new GraphQLIndicatorEntityConfigRepository();
const userRepository = new GraphQLUserRepository();
export const reportingSearchFilterAtom = atom<string | undefined>(undefined);
export const reportingContributorsSearchFilterAtom = atom<string | undefined>(
    undefined,
);
export const toFillSwitchAtom = atom<boolean>(false);
export const activeReportingTableColumnsAtom = atomWithStorage<
    (string | null)[]
>("__beavr_activeReportingTableColumnsAtom", ["subtheme", "assignedTo"]);

export const indicatorsAtom = atomWithQuery((get) => {
    const activeReportingEntityId = get(activeReportingEntityIdAtom);
    return {
        queryKey: [reportingKeys.indicators, { activeReportingEntityId }],
        queryFn: async () => indicatorRepository.get(activeReportingEntityId),
    };
});

export const datapointGroupsAtom = atomWithQuery(() => {
    return {
        queryKey: [reportingKeys.datapointGroups, {}],
        queryFn: datapointGroupRepository.get,
    };
});

export const reportingContributorsDataAtom = atomWithQuery(() => {
    return {
        queryKey: [reportingKeys.reportingContributors, {}],
        queryFn: userRepository.getWithReportingStats,
    };
});

export const filteredReportingContributorsAtom = atom((get) => {
    const { data, isPending } = get(reportingContributorsDataAtom);
    const reportingContributorsSearch = get(
        reportingContributorsSearchFilterAtom,
    );
    return {
        data: filterContributors(
            (data?.users || []) as User[],
            reportingContributorsSearch,
        ),
        isPending,
    };
});

export const createDatapointGroupAtom = atomWithMutation(() => ({
    mutationKey: [reportingKeys.createDatapointGroup],
    mutationFn: async (input: CreateDatapointGroupInput) => {
        return await datapointGroupRepository.create(input);
    },
    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [reportingKeys.datapointGroups],
        });
    },
}));

export const datapointGroupsOfEntityAtom = atom((get) => {
    const activeReportingEntityId = get(activeReportingEntityIdAtom);
    const { data: datapointGroupsData } = get(datapointGroupsAtom);
    return (
        datapointGroupsData?.datapointGroups
            ?.filter((group) => group?.entity?.id === activeReportingEntityId)
            .sort(
                (d1, d2) =>
                    datapointGroupsData?.datapointGroups?.find(
                        (elt) => elt.id === d1.id,
                    )?.period?.year -
                    datapointGroupsData?.datapointGroups?.find(
                        (elt) => elt.id === d2.id,
                    )?.period?.year,
            ) || []
    );
});

export const activeIndicatorsAtom = atom((get) => {
    const { data, isPending } = get(indicatorsAtom);
    const reportingSearch = get(reportingSearchFilterAtom);
    const toFillSwitch = get(toFillSwitchAtom);
    const groupsOfEntity = get(datapointGroupsOfEntityAtom);
    const activeDatapointGroupsIds = get(activeDatapointGroupIdsAtom);

    return {
        data: filterIndicators(
            data?.indicators2,
            reportingSearch,
            toFillSwitch,
            groupsOfEntity,
            activeDatapointGroupsIds,
        ),
        isPending,
    };
});

export const inactiveIndicatorsAtom = atom((get) => {
    const indicators = get(indicatorsAtom);
    return (
        indicators?.data?.indicators2?.filter(
            (indicator) => !indicator?.entityConfig?.active,
        ) || []
    );
});

export const sendRemindersAtom = atomWithMutation((get) => ({
    mutationKey: [reportingKeys.sendReminders],
    mutationFn: async (input: ReportingRemindersInput) => {
        await indicatorRepository.sendReminders(input);
    },
    onSettled() {
        const activeReportingEntityId = get(activeReportingEntityIdAtom);
        queryClient.invalidateQueries({
            queryKey: [reportingKeys.indicators, { activeReportingEntityId }],
        });
    },
}));

export const selectedUsersAtom = atom<(string | null)[]>([]);
export const selectedSubthemesAtom = atom<string[]>([]);
export const selectedTagsAtom = atom<(string | null)[]>([]);
export const searchUsersFilterAtom = atom<string>("");
export const selectedInactiveSubthemesAtom = atom<(string | null)[]>([]);
export const searchThemesFilterAtom = atom<string>("");
export const searchThemesActiveFilterAtom = atom<string | undefined>(undefined);
export const openReminderModalAtom = atom<boolean>(false);
export const selectedActiveFrameworkIdsAtom = atom<(string | null)[]>([]);
export const selectedInactiveFrameworkIdsAtom = atom<(string | null)[]>([]);

export const updateIndicatorEntityConfigAtom = atomWithMutation(() => ({
    mutationKey: [reportingKeys.updateIndicatorEntityConfig],
    mutationFn: async ({
        indicatorEntityConfigId,
        set,
    }: {
        indicatorEntityConfigId: string;
        set: IndicatorEntityConfigPatch;
    }) => {
        await indicatorEntityConfigRepository.update({
            indicatorEntityConfigId,
            set,
        });
    },
    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [reportingKeys.indicators],
        });
    },
}));

export const updateManyIndicatorEntityConfigAtom = atomWithMutation((get) => ({
    mutationKey: [reportingKeys.updateManyIndicatorEntityConfig],
    mutationFn: async ({
        indicatorEntityConfigIds,
        set,
    }: {
        indicatorEntityConfigIds: string[];
        set: IndicatorEntityConfigPatch;
    }) => {
        await indicatorEntityConfigRepository.updateMany({
            indicatorEntityConfigIds,
            set,
        });
    },
    onSettled() {
        const activeReportingEntityId = get(activeReportingEntityIdAtom);
        queryClient.invalidateQueries({
            queryKey: [reportingKeys.indicators, { activeReportingEntityId }],
        });
    },
}));

export const upsertDatapointAtom = atomWithMutation((get) => ({
    mutationKey: [reportingKeys.upsertDatapoint],
    mutationFn: async ({ set }: { set: DatapointUpsertInput }) => {
        await datapointRepository.upsert({
            set,
        });
    },
    onSettled() {
        const activeReportingEntityId = get(activeReportingEntityIdAtom);
        queryClient.invalidateQueries({
            queryKey: [reportingKeys.indicators, { activeReportingEntityId }],
        });
    },
}));

export const exportReportingAtom = atomWithMutation((get) => ({
    mutationKey: [reportingKeys.exportReporting],
    mutationFn: async () => {
        const indicators = get(activeIndicatorsAtom);
        const input: ReportingExportInput = {
            indicatorIds: indicators.data.map((indicator) => indicator.id),
            groupIds: get(activeDatapointGroupIdsAtom),
            entityId: get(activeReportingEntityIdAtom),
        };

        return exportReporting(datapointRepository, input);
    },
}));

export const exportReportingFilesAtom = atomWithMutation((get) => ({
    mutationKey: [reportingKeys.exportReportingFiles],
    mutationFn: async () => {
        const indicators = get(activeIndicatorsAtom);
        const input: ReportingExportInput = {
            indicatorIds: indicators.data.map((indicator) => indicator.id),
            groupIds: get(activeDatapointGroupIdsAtom),
            entityId: get(activeReportingEntityIdAtom),
        };

        return exportReportingFiles(datapointRepository, input);
    },
}));

export const updateIndicatorMutation = atomWithMutation(() => ({
    mutationKey: [reportingKeys.updateIndicator],
    mutationFn: async ({
        id,
        patch,
    }: {
        id: string;
        patch: IndicatorPatch;
    }) => {
        await indicatorRepository.update({
            id,
            patch,
        });
    },
    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [reportingKeys.indicators],
        });
    },
}));
