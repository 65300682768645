import { currentUserAtom } from "@app/store/userStore";
import { checklistValidationRequiredAtom } from "@app/store/versionStore";
import { isPdfFile } from "@app/usecases/VersionUseCases/utils";
import { TiptapCollabProvider, WebSocketStatus } from "@hocuspocus/provider";
import ThreadsKit from "@tiptap-pro/extension-comments";
import TableOfContents, {
    TableOfContentData,
    getHierarchicalIndexes,
} from "@tiptap-pro/extension-table-of-contents";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { Editor } from "@tiptap/react";
import { useAtomValue } from "jotai";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import * as Y from "yjs";
import {
    DocumentVersion,
    DocumentVersionStatus,
    GetCurrentUserQuery,
} from "../../../../../generated/client/graphql";
import { EditorUser } from "../../../../components/TipTap/components/BlockEditor/types";
import ExtensionKit from "../../../../components/TipTap/extensions/extension-kit";
import { useEditorState } from "../../../../components/TipTap/hooks/useBlockEditor";
import { userColors } from "../../../../components/TipTap/lib/constants";
import { randomElement } from "../../../../components/TipTap/lib/utils";

export const EditorMode = {
    REGULAR: "REGULAR",
    HISTORY: "HISTORY",
} as const;

export type EditorModeType = (typeof EditorMode)[keyof typeof EditorMode];

export const RegularTabs = {
    INFO: "INFO",
    CHECKS: "CHECKS",
    CONTENT: "CONTENT",
    COMMENTS: "COMMENTS",
} as const;
export type RegularTabType = (typeof RegularTabs)[keyof typeof RegularTabs];

export const CommentsTabs = {
    OPEN: "OPEN",
    RESOLVED: "RESOLVED",
} as const;
type CommentTabType = (typeof CommentsTabs)[keyof typeof CommentsTabs];

export type EditorContextType = {
    ydoc: Y.Doc | null;
    provider: TiptapCollabProvider | null;
    editor: Editor | null;
    editable: boolean;
    tableOfContentsItems: TableOfContentData;
    collabState: WebSocketStatus;
    users: EditorUser[];
    version: DocumentVersion | undefined | null;
    editorMode: EditorModeType;
    setEditorMode: (editorMode: EditorModeType) => void;
    regularModeActiveTab: RegularTabType;
    setRegularModeActiveTab: (activeTab: RegularTabType) => void;
    commentModeActiveTab: CommentTabType;
    isPdf: boolean;
    setCommentModeActiveTab: (activeTab: CommentTabType) => void;
    showValidationChecklist: boolean;
};
export const EditorContext = createContext<EditorContextType | null>(null);

export const useEditorContext = () => {
    const context = useContext(EditorContext);
    if (!context) {
        throw new Error(
            "Dropdown compound components must be rendered within the Dropdown component",
        );
    }
    return context;
};

export type EditorProps = {
    tipTapDocId: string | null | undefined;
    tipTapJwt: string | null | undefined;
    user?: GetCurrentUserQuery["currentUser"];
    editable: boolean;
};

const getProvider = (
    tipTapDocId: string | null | undefined,
    tipTapJwt: string | null | undefined,
) => {
    if (!tipTapDocId || !tipTapJwt) {
        return { ydoc: null, provider: null };
    }

    const ydoc = new Y.Doc();
    const provider = new TiptapCollabProvider({
        name: tipTapDocId,
        appId: "ykoll4k5",
        token: tipTapJwt,
        document: ydoc,
        preserveConnection: false,
    });

    return { ydoc, provider };
};

const getEditor = (
    ydoc: Y.Doc | null,
    provider: TiptapCollabProvider | null,
    user: GetCurrentUserQuery["currentUser"] | undefined,
    editable: boolean,
    setItems: (items: any) => void,
) => {
    if (!ydoc || !provider || !user) return null;

    return new Editor({
        editable,
        autofocus: true,
        onCreate: () => {
            provider?.on("synced", () => {});
        },

        extensions: [
            ...ExtensionKit({
                provider,
            }),
            Collaboration.configure({
                document: ydoc,
            }),
            CollaborationCursor.configure({
                provider: provider || undefined,
                user: {
                    name: `${user?.firstName} ${user?.lastName}`,
                    color: randomElement(userColors),
                },
            }),
            ThreadsKit.configure({
                provider: provider,
            }),
            TableOfContents.configure({
                getIndex: getHierarchicalIndexes,
                onUpdate(content) {
                    setItems(content);
                },
            }),
        ],
        editorProps: {
            attributes: {
                autocomplete: "off",
                autocorrect: "off",
                autocapitalize: "off",
                class: "min-h-full",
            },
            transformPastedHTML(html) {
                const cleanRecursively = (el: HTMLElement) => {
                    [...el.attributes].forEach((attr) => {
                        el.removeAttribute(attr.name);
                    });
                    [].forEach.call(el.children, (child: HTMLElement) => {
                        cleanRecursively(child);
                    });
                };
                var el = document.createElement("utils");
                el.innerHTML = html;
                cleanRecursively(el);
                return el.innerHTML;
            },
        },
    });
};

export function useCreateEditorContext({
    tipTapDocId,
    tipTapJwt,
    user,
    editable,
}: EditorProps) {
    const [items, setItems] = useState<any>([]);
    const [editorMode, setEditorMode] = useState<EditorModeType>(
        EditorMode.REGULAR,
    );
    const [regularModeActiveTab, setRegularModeActiveTab] =
        useState<RegularTabType>(RegularTabs.COMMENTS);
    const [commentModeActiveTab, setCommentModeActiveTab] =
        useState<CommentTabType>(CommentsTabs.OPEN);

    const [provider, setProvider] = useState<TiptapCollabProvider | null>(null);
    const [ydoc, setYdoc] = useState<Y.Doc | null>(null);

    const editor = useMemo(
        () => getEditor(ydoc, provider, user, editable, setItems),
        [ydoc, provider, user?.id, editable],
    );

    const {
        data: { showValidation },
        isPending,
    } = useAtomValue(checklistValidationRequiredAtom);

    useEffect(() => {
        const { ydoc, provider } = getProvider(tipTapDocId, tipTapJwt);
        setProvider(provider);
        setYdoc(ydoc);

        return () => {
            provider?.disconnect();
        };
    }, [tipTapDocId, tipTapJwt]);

    return {
        ydoc,
        provider,
        editor,
        editable,
        tableOfContentsItems: items,
        editorMode,
        setEditorMode,
        regularModeActiveTab,
        setRegularModeActiveTab,
        commentModeActiveTab,
        setCommentModeActiveTab,
        showValidationChecklist: showValidation && !isPending,
    };
}

export function useCreateDocumentEditorContext(
    version?: DocumentVersion | null,
): EditorContextType {
    const { data } = useAtomValue(currentUserAtom);

    const editable =
        !!version?.withEditor &&
        (version?.status === DocumentVersionStatus.Draft ||
            version?.status === DocumentVersionStatus.Rejected);

    const ctx = useCreateEditorContext({
        tipTapDocId: version?.tipTapDocId,
        tipTapJwt: version?.tipTapJwt,
        user: data?.currentUser,
        editable,
    });

    const editorState = useEditorState({
        editor: ctx.editor,
        provider: ctx.provider,
    });

    const isPdf = isPdfFile(version?.finalFile?.extension);
    return { ...ctx, ...editorState, editable, isPdf, version };
}
