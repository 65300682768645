export type Period = {
    year: number;
    quarter?: string;
    month?: string;
};

export const humanReadablePeriodToPeriod = (urlPeriods: string[]): Period[] => {
    return urlPeriods
        .map((period) => {
            if (!period) return null;
            const year = parseInt(period.slice(0, 4));
            const rest = period.slice(4);
            let quarter = undefined;
            let month = undefined;
            if (!!rest.length && rest[0] === "Q") {
                quarter = rest;
            } else if (!!rest.length && rest[0] !== "Q") {
                month = rest;
            }
            return { year, quarter, month };
        })
        .filter((period) => period !== null) as Period[];
};

export const periodToHumanReadablePeriod = (period: {
    year: number;
    quarter?: string;
    month?: string;
}) => {
    return (
        period.year.toString() +
        (period.quarter ? period.quarter : period.month ? period.month : "")
    );
};
