import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
    CreateTagDocument,
    GetTagsDocument,
    Tag,
    TagType,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import ButtonSmall from "../../../Button/ButtonSmall";
import TextInput from "../../../inputs_v2/Text";
import PlusDropDownItem from "../../MultiSelect/PlusDropdownItem";
import CheckboxItem from "../../components/dropdowns/items/CheckboxItem";
import DropdownItemSearch from "../../components/dropdowns/items/Search";
import DropdownContentLayout from "../../components/dropdowns/layout";
import './i18n';
import { useTranslation } from "react-i18next";

const TagDropdown = ({
    tagIds,
    onTagIdsChange,
    tags,
    type,
    hidePlus,
    ...props
}: {
    tagIds?: string[] | undefined;
    onTagIdsChange: (tagIds: string[]) => void;
    type: TagType;
    tags: Tag[] | undefined;
    hidePlus?: boolean;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("TagsDropdown");

    const [newTag, setNewTag] = useState<string>();
    const [searchFilterString, setSearchFilterString] = useState("");

    const { mutate } = useGraphQLMutation(CreateTagDocument, {}, true);
    const client = useQueryClient();
    const onSave = () => {
        newTag &&
            mutate(
                { type: type, name: newTag },
                {
                    onSuccess: () => {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetTagsDocument, {
                                type: type,
                            }),
                        });
                    },
                }
            );
    };
    const onToggle = (id: string) => {
        if (tagIds?.includes(id)) {
            const newValues = [...tagIds];
            newValues.splice(tagIds.indexOf(id), 1);
            onTagIdsChange(newValues);
        } else {
            onTagIdsChange([...(tagIds || []), id]);
        }
    };

    return (
        <DropdownContentLayout {...props}>
            <DropdownItemSearch
                searchFilterString={searchFilterString}
                setSearchFilterString={setSearchFilterString}
            />

            {!hidePlus && (
                <PlusDropDownItem
                    title={t('plus_title')}
                    SaveButton={({ onClick }) => (
                        <ButtonSmall
                            disabled={!newTag}
                            onClick={() => {
                                onClick();
                                onSave();
                            }}
                        >
                            {t('create_tag')}
                        </ButtonSmall>
                    )}
                >
                    <div className="text-sm font-bold">{t("create_new_tag")}</div>
                    <TextInput value={newTag} setValue={setNewTag} />
                </PlusDropDownItem>
            )}
            {tags
                ?.filter(
                    ({ name }) =>
                        !searchFilterString ||
                        (name &&
                            name
                                .toLowerCase()
                                .includes(searchFilterString.toLowerCase()))
                )
                .map(({ id, name }) => (
                    <CheckboxItem
                        key={id}
                        onSelect={(e) => {
                            e.preventDefault();
                        }}
                        checked={tagIds?.includes(id)}
                        onCheckedChange={() => onToggle(id)}
                    >
                        {name}
                    </CheckboxItem>
                ))}
        </DropdownContentLayout>
    );
};

export default TagDropdown;
