import Avatar from "@app/components/User/Avatar";
import { datapointGroupsAtom } from "@app/store/reportingStore";
import { getFilteredSortedDatapointGroupsIds } from "@app/usecases/ReportingUseCases";

import {
    activeDatapointGroupIdsAtom,
    activeReportingEntityIdAtom,
} from "@app/pages/Reporting/context";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ColumnDef } from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DatapointGroup, User } from "../../../../../generated/client/graphql";
import CheckboxCell from "../../../../components/Table/CheckboxCell";
import Header from "../../../../components/Table/Header";
import CheckboxHeader from "../../../../components/Table/Header/Checkbox";
import "../../i18n";

const selectCol: ColumnDef<User> = {
    id: "select",
    header: ({ table }) => {
        return <CheckboxHeader table={table} />;
    },
    cell: ({ row }) => <CheckboxCell row={row} />,
    meta: {
        headerClassName: "w-[50px]",
    },
};

const contributorCol: ColumnDef<User> = {
    id: "contributor",
    accessorKey: "contributor",
    meta: {
        headerClassName: "w-[200px]",
    },
    header: ({ column }) => {
        const { t } = useTranslation("ReportingContributorsPage");
        return <Header title={t("contributor_column_title")} column={column} />;
    },
    cell: ({ row }) => {
        return (
            <FlexRow p="3" gap="3" alignItems="center">
                <Avatar
                    avatarUrl={row.original?.avatar?.signedUrl}
                    initials={row.original?.initials}
                    size="medium"
                />
                <Text
                    variant="body-bold"
                    className="capitalize"
                >{`${row.original?.firstName} ${row.original?.lastName}`}</Text>
            </FlexRow>
        );
    },
};

const dataCol = (
    datapointGroupId: string,
    datapointGroups: any[],
): ColumnDef<User> => {
    return {
        id: `data_${datapointGroupId}`,
        accessorKey: `data_${datapointGroupId}`,
        meta: {
            headerClassName: "w-[150px]",
        },
        header: ({ column }) => {
            return (
                <Header
                    title={
                        datapointGroups.find(
                            (elt) => elt.id === datapointGroupId,
                        )?.period?.year || ""
                    }
                    column={column}
                    disableSort={true}
                />
            );
        },
        cell: ({ row }) => {
            const contributorStat = row.original.reportingStats?.find(
                (elt) => elt?.datapointGroupId === datapointGroupId,
            );
            const { t } = useTranslation("ReportingContributorsPage");
            return (
                <FlexRow gap="2">
                    {contributorStat?.assignedDatapoints &&
                    contributorStat?.assignedDatapoints !== 0 ? (
                        <Text variant="body-sm">{`${contributorStat?.answeredDatapoints} / ${contributorStat?.assignedDatapoints} `}</Text>
                    ) : null}
                    <ProgressBar
                        completed={contributorStat?.answeredDatapoints || 0}
                        total={contributorStat?.assignedDatapoints || 0}
                        noDataPlaceholder={t("progress_bar_no_data")}
                    ></ProgressBar>
                </FlexRow>
            );
        },
    } as ColumnDef<User>;
};

export const useColumns = (): ColumnDef<User>[] => {
    const activeDatapointGroupsIds = useAtomValue(activeDatapointGroupIdsAtom);
    const { data: datapointGroupsData } = useAtomValue(datapointGroupsAtom);
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    return useMemo(() => {
        return [
            selectCol,
            contributorCol,
            ...getFilteredSortedDatapointGroupsIds(
                activeDatapointGroupsIds,
                activeReportingEntityId,
                (datapointGroupsData?.datapointGroups as DatapointGroup[]) ||
                    [],
            ).map((id) => {
                return dataCol(id, datapointGroupsData?.datapointGroups || []);
            }),
        ];
    }, [activeReportingEntityId, activeDatapointGroupsIds]);
};
