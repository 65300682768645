import i18next from "@app/i18n";

i18next.addResourceBundle("en", "FrameworkDatapoint", {
    comment: "Comment ({{charCount}} characters)",
    ownership: "Ownership",
    validate: "Validate datapoint",
    value: "Value ({{ unit }})",
    yes_no: "Value (Yes/No)",
    yes: "Yes",
    no: "No",
    manage_materiality: "Manage materiality",
    update_success: "Update successful",
    update_error: "Error updating",
    owner: "Owner :",
    required_error: "Value is required",
    required_comment_error: "Comment is required",
    no_data: "No data",
    data_available: "Data available",
    data_is_required: "Data is mandatory for this datapoint",
});

i18next.addResourceBundle("fr", "FrameworkDatapoint", {
    comment: "Commentaire ({{charCount}} caractères)",
    ownership: "Assigné à:",
    validate: "Valider le datapoint",
    value: "Valeur ({{ unit }})",
    yes_no: "Valeur (Oui/Non)",
    yes: "Oui",
    no: "Non",
    manage_materiality: "Gérer la matérialité",
    update_success: "Mis à jour avec succès",
    update_error: "Erreur lors de la mise à jour",
    owner: "Assigné à :",
    required_comment_error: "Un commentaire est is required",
    no_data: "Pas de donnée",
    data_available: "Donnée disponible",
    data_is_required:
        "Remplir la donnée est obligatoire pour ce point de données",
});
