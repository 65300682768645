import {
    CreateEntityDocument,
    DeleteEntityDocument,
    GetAllEntitiesDocument,
    GetAllEntitiesQuery,
    UpdateEntityDocument,
    type CreateEntityMutationVariables,
    type UpdateEntityMutationVariables,
} from "../../../generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { EntityRepository } from "../EntityRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLEntityRepository implements EntityRepository {
    getMany = (): Promise<GetAllEntitiesQuery> => {
        return graphqlClientKoyeb.request(
            GetAllEntitiesDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    create = (input: CreateEntityMutationVariables) => {
        return graphqlClientKoyeb.request(
            CreateEntityDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };

    delete = (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteEntityDocument,
            { id },
            AccessHeadersHolder.getHeaders(),
        );
    };

    update = (input: UpdateEntityMutationVariables) => {
        return graphqlClientKoyeb.request(
            UpdateEntityDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };
}
