import UserSelect from "@app/components/Dropdown/dropdowns/User";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { Switch } from "@design-system/Inputs/Switch";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    BooleanEnum,
    CsrdDatapointPatch,
    CsrdDatapointStatus,
    CsrdDatapointType,
    MaterialityStatus,
    ValidationStatus,
} from "@generated/client/graphql";
import { useAtomValue, useSetAtom } from "jotai";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import { useConditionalScrollTo } from "@app/shared/utils/scroll";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { CsrdDatapoint } from "../../../types";
import { activeDatapointIdAtom, openMaterialityModalAtom } from "../../ctx";
import { PillarDatapoint } from "../../types";
import { DatapointValueInput } from "./DatapointValueInput";
import "./i18n";
import { UseDatapointStateType, useDatapoint } from "./useDatapoint";
import { Prefill } from "../Prefill"

export const DatapointForm: FC<{ datapoint: PillarDatapoint }> = ({
    datapoint,
}) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const { datapointState: state, handleChange } = useDatapoint(datapoint);

    const selectedDatapointId = useAtomValue(activeDatapointIdAtom);

    const scrollToDatapointRef = useConditionalScrollTo(
        selectedDatapointId === datapoint.cmsId,
    );

    const handleOwnerChange = (userId: string | undefined) => {
        handleChange({ ownerId: userId ?? null });
    };
    const handleValidationChange = (validated: boolean) => {
        handleChange({
            status: validated
                ? ValidationStatus.Validated
                : ValidationStatus.Notvalidated,
        });
    };

    const debounchedUpdateComment = useDebouncedStableCallback((comment) => {
        handleChange({ comment: comment });
    }, 300);

    const handleCommentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        state.setComment(event.target.value);
        state.setCharCount(event.target.value.length);
        debounchedUpdateComment(event.target.value);
        if (
            datapoint.type === CsrdDatapointType.Narrative &&
            !event.target.value
        ) {
            state.setCommentError(t("required_comment_error"));
        } else {
            state.setCommentError(undefined);
        }
    };

    return (
        <Box w="full" ref={scrollToDatapointRef}>
            {state.isCollapsed ? (
                <FlexRow
                    px="4"
                    alignItems="start"
                    gap="4"
                    alignSelf="stretch"
                    className={cn(
                        "border-b border-tertiary min-h-20",
                        datapoint.status === CsrdDatapointStatus.NotMaterial &&
                            "bg-neutral-100",
                    )}
                >
                    <Box py="5">
                        <StatusTagCircular status={state.status} size="sm" />
                    </Box>
                    <FlexCol
                        w="full"
                        h="full"
                        py="4"
                        className=" cursor-pointer"
                        onClick={() => state.setIsCollapsed(!state.isCollapsed)}
                    >
                        <Text variant="body-lg-bold">{datapoint?.name}</Text>
                    </FlexCol>
                    <Box py="4">
                        <ActionDropdown
                            datapoint={datapoint}
                            datapointState={state}
                            handleChange={handleChange}
                        />
                    </Box>
                </FlexRow>
            ) : (
                <FlexRow
                    gap="4"
                    px="4"
                    alignItems="start"
                    w="full"
                    className="flex-1 border-b border-tertiary"
                >
                    <Box py="5">
                        <StatusTagCircular status={state.status} size="sm" />
                    </Box>

                    <FlexCol gap="4" alignItems="start" w="full" py="4">
                        <FlexCol
                            w="full"
                            justifyContent="start"
                            className="cursor-pointer"
                            onClick={() =>
                                state.setIsCollapsed(!state.isCollapsed)
                            }
                        >
                            <Text variant="body-lg-bold">
                                {datapoint?.name}
                            </Text>
                        </FlexCol>
                        <FlexRow
                            className="h-[28px]"
                            alignItems="center"
                            justifyContent="between"
                            w="full"
                        >
                            <FlexRow className="gap-[6px]" alignItems="center">
                                <Text variant="body-sm" color="secondary">
                                    {t("owner")}
                                </Text>
                                <UserSelect
                                    selectedUserId={state.ownerId ?? undefined}
                                    onChange={handleOwnerChange}
                                />
                            </FlexRow>
                            <Prefill
                                datapointId={datapoint.id}
                                hasIndicator={!!datapoint.indicatorCmsId}
                                onConfirm={handleChange}
                            />
                        </FlexRow>
                        <DatapointValueInput
                            datapoint={datapoint}
                            state={state}
                            handleChange={handleChange}
                        />
                        <FlexRow w="full" className="relative">
                            <Textarea
                                placeholder={t("comment", {
                                    charCount: state.charCount,
                                })}
                                value={state.comment}
                                onChange={handleCommentChange}
                                className="w-full"
                                disabled={
                                    datapoint?.validationStatus ===
                                        ValidationStatus.Validated ||
                                    datapoint?.status ===
                                        CsrdDatapointStatus.NotMaterial
                                }
                                error={state.commentError}
                            ></Textarea>
                            {state.commentIsUpdating !== undefined && (
                                <Icon
                                    name={
                                        state.commentIsUpdating
                                            ? "circleLoading"
                                            : "check"
                                    }
                                    className={cn(
                                        "absolute right-2 top-2",
                                        state.commentIsUpdating
                                            ? "text-secondary"
                                            : "text-beavrGreen",
                                    )}
                                    size="sm"
                                />
                            )}
                        </FlexRow>
                        <Switch
                            checked={state.validated}
                            onChange={handleValidationChange}
                            label={t("validate")}
                            labelPosition="right"
                            disabled={
                                !!state.valueError || !!state.commentError
                            }
                        />
                    </FlexCol>
                    <Box py="4">
                        <ActionDropdown
                            datapoint={datapoint}
                            datapointState={state}
                            handleChange={handleChange}
                        />
                    </Box>
                </FlexRow>
            )}
        </Box>
    );
};

const ActionDropdown: FC<{
    datapoint: CsrdDatapoint;
    datapointState: UseDatapointStateType;
    handleChange: (patch: CsrdDatapointPatch) => void;
}> = ({ datapoint, datapointState, handleChange }) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const openMaterialityModal = useSetAtom(openMaterialityModalAtom);

    const handleMaterialityModalSelect = () => {
        openMaterialityModal(
            datapoint?.materiality?.status as MaterialityStatus,
            datapoint?.materiality?.comment || "",
            datapoint?.id,
            undefined,
            datapoint?.name,
        );
    };

    const handleNoDataChange = (_: Event) => {
        datapointState.setValueNoData(!datapointState.valueNoData);
        handleChange({
            value: {
                noData:
                    datapointState.valueNoData === true
                        ? BooleanEnum.False
                        : datapointState.valueNoData === false
                          ? BooleanEnum.True
                          : BooleanEnum.Unknown,
            },
        });
    };

    return (
        <DropdownMenu modal={false}>
            <DropdownMenu.Trigger>
                <Icon name="more" />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
                {
                    <Tooltip delayDuration={0}>
                        <Tooltip.Trigger>
                            <DropdownMenu.Item
                                onSelect={handleNoDataChange}
                                disabled={
                                    datapoint?.mandatory === BooleanEnum.True
                                }
                            >
                                <DropdownMenu.ItemIcon
                                    name={
                                        datapointState.valueNoData
                                            ? "edit"
                                            : "editCrossed"
                                    }
                                />

                                <FlexRow w="full">
                                    <Text
                                        variant="body"
                                        color={
                                            datapoint?.mandatory ===
                                            BooleanEnum.True
                                                ? "disabled"
                                                : "dark"
                                        }
                                    >
                                        {datapointState.valueNoData
                                            ? t("data_available")
                                            : t("no_data")}
                                    </Text>
                                </FlexRow>
                            </DropdownMenu.Item>
                        </Tooltip.Trigger>
                        {datapoint?.mandatory === BooleanEnum.True && (
                            <Tooltip.Content>
                                {t("data_is_required")}
                            </Tooltip.Content>
                        )}
                    </Tooltip>
                }
                <DropdownMenu.Item onSelect={handleMaterialityModalSelect}>
                    <DropdownMenu.ItemIcon name="eyeOff" />
                    <FlexRow alignItems="center">
                        <Text variant="body" color="dark">
                            {t("manage_materiality")}
                        </Text>
                    </FlexRow>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu>
    );
};
