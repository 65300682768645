import { DefinedQueryObserverResult } from "@tanstack/react-query";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Download, MoreHoriz, Trash } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    DeleteDraftDocument,
    DocumentVersion,
    DocumentVersionStatus,
    GetDocumentByUrlQuery,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import {
    DropdownMenu,
    DropdownTrigger,
} from "../../../../components/Dropdown/components";
import BaseItem from "../../../../components/Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../../../components/Dropdown/components/dropdowns/layout";
import FullFrameBlock from "../../../../components/Layouts/FullFrame/Block";
import InnerLayout from "../../../../components/Layouts/FullFrame/Block/InnerLayout";
import { VersionBlockIllustration } from "./VersionBlockIllustration";
import { VersionInfoTooltip } from "./VersionInfoTooltip";
import "./i18n";

const VersionBlock = ({
    version,
    getDocumentQuery,
    isLatest,
}: {
    version: DocumentVersion;
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
    isLatest: boolean;
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation("DocumentVersionBlock");
    dayjs.extend(LocalizedFormat);
    const isUploadedFile = !version.withEditor;

    const openFile = (
        e?: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    ) => {
        if (version.finalFile?.signedUrl)
            window.open(version.finalFile.signedUrl, "_blank")?.focus();
        e?.stopPropagation();
    };
    const deleteMutation = useGraphQLMutation(DeleteDraftDocument, {}, true);
    const onDelete = (e: React.MouseEvent<HTMLDivElement>) => {
        deleteMutation.mutate(
            {
                versionId: version.id,
            },
            { onSuccess: () => getDocumentQuery.refetch() },
        );
        e.stopPropagation();
    };
    const handleBlockClick = () => {
        if (isLatest) return navigate("edit");
        openFile();
    };
    return (
        <div className="group">
            <FullFrameBlock
                className="hover:bg-secondary hover:outline hover:outline-green hover:outline-1 hover:border-green cursor-pointer"
                onClick={handleBlockClick}
            >
                <InnerLayout className="p-4 flex space-x-5">
                    <VersionBlockIllustration
                        extension={
                            isUploadedFile ? version.finalFile?.extension : null
                        }
                        status={version.status}
                    />
                    <div className="pt-2 flex-grow space-y-1">
                        <div className="text-lg font-bold flex items-center space-x-1.5">
                            <span>
                                {dayjs(
                                    version.approvedAt || version.lastEditedAt,
                                ).format("LL")}{" "}
                                {version.status ===
                                    DocumentVersionStatus.Draft && t("draft")}
                            </span>
                            {isUploadedFile && version.finalFile?.name && (
                                <VersionInfoTooltip
                                    tooltip={version.finalFile.name}
                                />
                            )}
                        </div>
                        <div>
                            <div className="flex items-center text-sm font-regular space-x-1.5">
                                <span>{t("last_edited_by")}</span>
                                <span>
                                    {version.lastEditedBy?.firstName}{" "}
                                    {version.lastEditedBy?.lastName[0]}.
                                </span>
                                <svg width={4} height={4}>
                                    <ellipse
                                        cx="2"
                                        cy="2"
                                        rx="2"
                                        ry="2"
                                        className="fill-neutral-500"
                                    />
                                </svg>
                                <span>
                                    {t("last_edit_time", {
                                        date: dayjs(
                                            version.lastEditedAt,
                                        ).format("LL"),
                                        time: dayjs(
                                            version.lastEditedAt,
                                        ).format("LT"),
                                    })}
                                </span>
                                <span></span>
                                {version.approvedBy && (
                                    <>
                                        <svg width={4} height={4}>
                                            <ellipse
                                                cx="2"
                                                cy="2"
                                                rx="2"
                                                ry="2"
                                                className="fill-neutral-500"
                                            />
                                        </svg>
                                        <span>{t("approved_by")}</span>
                                        <span>
                                            {version.approvedBy?.firstName}{" "}
                                            {version.approvedBy?.lastName[0]}.
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {version.status !== DocumentVersionStatus.Approved && (
                        <div className="flex space-x-2">
                            <DropdownMenu>
                                <DropdownTrigger asChild>
                                    <ButtonSmall
                                        loading={
                                            deleteMutation.isPending ||
                                            getDocumentQuery.isRefetching
                                        }
                                        variant={ButtonType.TONAL}
                                        IconLeft={MoreHoriz}
                                    />
                                </DropdownTrigger>
                                <DropdownContentLayout
                                    align="start"
                                    side="right"
                                    sideOffset={8}
                                >
                                    {version.finalFile && (
                                        <BaseItem
                                            IconLeft={Download}
                                            onClick={openFile}
                                        >
                                            {t("download")}
                                        </BaseItem>
                                    )}

                                    <BaseItem
                                        IconLeft={() => (
                                            <Trash className="text-red-600 w-4 h-4" />
                                        )}
                                        onClick={onDelete}
                                    >
                                        {t("delete")}
                                    </BaseItem>
                                </DropdownContentLayout>
                            </DropdownMenu>
                        </div>
                    )}
                </InnerLayout>
            </FullFrameBlock>
            <div className="relative group-last:hidden">
                <div className="absolute w-[34px]  h-5 border-r border-seconday" />
            </div>
        </div>
    );
};

export default VersionBlock;
