import { forwardRef } from "react";
import { cn, type ClassValue } from "../Utilities";

import { ReactComponent as Add } from "./assets/add.svg";
import { ReactComponent as AngleDown } from "./assets/angle-down.svg";
import { ReactComponent as AngleLeft } from "./assets/angle-left.svg";
import { ReactComponent as AngleRight } from "./assets/angle-right.svg";
import { ReactComponent as AngleUpDown } from "./assets/angle-up-down.svg";
import { ReactComponent as AngleUp } from "./assets/angle-up.svg";
import { ReactComponent as ArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as ArrowRefresh } from "./assets/arrow-refresh.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { ReactComponent as ArrowUp } from "./assets/arrow-up.svg";
import { ReactComponent as Building } from "./assets/building.svg";
import { ReactComponent as Check } from "./assets/check.svg";
import { ReactComponent as CircleInProgress } from "./assets/circle-in-progress.svg";
import { ReactComponent as CircleLoading } from "./assets/circle-loading.svg";
import { ReactComponent as CircleTodo } from "./assets/circle-todo.svg";
import { ReactComponent as CircleValidated } from "./assets/circle-validated.svg";
import { ReactComponent as Close } from "./assets/close.svg";
import { ReactComponent as CloudWithValidateIcon } from "./assets/cloud-with-validate.svg";
import { ReactComponent as Comment } from "./assets/comment.svg";
import { ReactComponent as Company } from "./assets/company.svg";
import { ReactComponent as DashboardIcon } from "./assets/dashboard.svg";
import { ReactComponent as Document } from "./assets/document.svg";
import { ReactComponent as DoubleCheck } from "./assets/double-check.svg";
import { ReactComponent as Download } from "./assets/download.svg";
import { ReactComponent as Duplicate } from "./assets/duplicate.svg";
import { ReactComponent as Edit } from "./assets/edit.svg";
import { ReactComponent as EyeOff } from "./assets/eye-off.svg";
import { ReactComponent as EyeOn } from "./assets/eye-on.svg";
import { ReactComponent as File } from "./assets/file.svg";
import { ReactComponent as Help } from "./assets/help.svg";
import { ReactComponent as Info } from "./assets/info.svg";
import { ReactComponent as ListCheck } from "./assets/list-check.svg";
import { ReactComponent as ListOrder } from "./assets/list-order.svg";
import { ReactComponent as List } from "./assets/list.svg";
import { ReactComponent as Loader } from "./assets/loader.svg";
import { ReactComponent as Megaphone } from "./assets/megaphone.svg";
import { ReactComponent as More } from "./assets/more.svg";
import { ReactComponent as Palette } from "./assets/palette.svg";
import { ReactComponent as Party } from "./assets/party.svg";
import { ReactComponent as PencilCrossed } from "./assets/pencil-crossed.svg";
import { ReactComponent as Plus } from "./assets/plus.svg";
import { ReactComponent as Redo } from "./assets/redo.svg";
import { ReactComponent as Ruler } from "./assets/ruler.svg";
import { ReactComponent as Scale } from "./assets/scale.svg";
import { ReactComponent as Search } from "./assets/search.svg";
import { ReactComponent as Settings } from "./assets/settings.svg";
import { ReactComponent as SignOut } from "./assets/sign-out.svg";
import { ReactComponent as Table } from "./assets/table.svg";
import { ReactComponent as Target } from "./assets/target.svg";
import { ReactComponent as Trash } from "./assets/trash.svg";
import { ReactComponent as Undo } from "./assets/undo.svg";
import { ReactComponent as UnfoldVertical } from "./assets/unfold-vertical.svg";
import { ReactComponent as Upload } from "./assets/upload.svg";
import { ReactComponent as UserDelete } from "./assets/user-delete.svg";
import { ReactComponent as User } from "./assets/user.svg";
import { ReactComponent as Users } from "./assets/users.svg";
import { ReactComponent as Validate } from "./assets/validate.svg";
import { ReactComponent as Warning } from "./assets/warning.svg";
import { ReactComponent as MagicWand } from "./assets/magic-wand.svg";

export const iconMap = {
    add: Add,
    angleDown: AngleDown,
    angleLeft: AngleLeft,
    angleRight: AngleRight,
    angleUpDown: AngleUpDown,
    angleUp: AngleUp,
    arrowDown: ArrowDown,
    arrowLeft: ArrowLeft,
    arrowRefresh: ArrowRefresh,
    arrowRight: ArrowRight,
    arrowUp: ArrowUp,
    building: Building,
    check: Check,
    close: Close,
    cloudWithValidate: CloudWithValidateIcon,
    comment: Comment,
    company: Company,
    dashboard: DashboardIcon,
    document: Document,
    doubleCheck: DoubleCheck,
    download: Download,
    duplicate: Duplicate,
    edit: Edit,
    editCrossed: PencilCrossed,
    file: File,
    help: Help,
    info: Info,
    list: List,
    listOrder: ListOrder,
    listCheck: ListCheck,
    loader: Loader,
    magicWand: MagicWand,
    megaphone: Megaphone,
    palette: Palette,
    party: Party,
    redo: Redo,
    ruler: Ruler,
    scale: Scale,
    search: Search,
    table: Table,
    target: Target,
    trash: Trash,
    unfoldVertical: UnfoldVertical,
    undo: Undo,
    upload: Upload,
    user: User,
    userDelete: UserDelete,
    users: Users,
    validate: Validate,
    warning: Warning,
    plus: Plus,
    more: More,
    circleTodo: CircleTodo,
    circleValidated: CircleValidated,
    circleLoading: CircleLoading,
    circleInProgress: CircleInProgress,
    eyeOff: EyeOff,
    eyeOn: EyeOn,
    settings: Settings,
    signOut: SignOut,
} as const;

export type IconName = keyof typeof iconMap;

export type IconProps = {
    name: keyof typeof iconMap;
    size?: "xs" | "sm" | "md" | "lg";
    className?: ClassValue;
};

export const Icon = forwardRef<SVGSVGElement, IconProps>(
    ({ name, size, className }, ref) => {
        const Comp = iconMap[name];

        // compute width and height based on size prop
        const sizeTw =
            size === "xs"
                ? "w-3 h-3"
                : size === "sm"
                  ? "w-4 h-4"
                  : size === "lg"
                    ? "w-6 h-6"
                    : "w-5 h-5"; // md is also the default size
        return <Comp ref={ref} className={cn(sizeTw, "shrink-0", className)} />;
    },
);
