import Container from "@app/components/Container";
import BodyHeader from "@app/shared/components/BodyHeader";
import EntitySelect from "@app/shared/components/EntitySelect";
import { FlexCol } from "@design-system/Layout/Flex";

import { usePageviewTrack } from "@app/shared/planhat";
import { useEntities } from "@app/store/entityStore";
import { useAtomValue } from "jotai";
import { MegaphoneIcon } from "lucide-react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddPeriodModal from "./components/AddPeriodModal";
import AddCustomIndicatorModal from "./components/CreateCustomIndicatorsModal";
import InactiveIndicatorsModal from "./components/InactiveIndicatorsModal";
import IndicatorsTable from "./components/IndicatorsTable";
import PeriodChipsSelect from "./components/PeriodChipsSelect";
import ReportingFilters from "./components/ReportingFilters";
import { activeReportingEntityIdAtom } from "./context";
import ReportingHeaderButton from "./header";
import { useSetReportingFilters } from "./hooks";
import "./i18n";

const ReportingV2: FC = () => {
    const { t } = useTranslation("ReportingPage");
    const { entities, isPending: isEntitiesLoading } = useEntities();
    const activeReportingEntityId = useAtomValue(activeReportingEntityIdAtom);

    const { setFilters } = useSetReportingFilters();

    const setActiveReportingEntityId = (id: string) => {
        setFilters({ activeReportingEntityId: id });
    };
    useEffect(() => {
        if (
            !activeReportingEntityId &&
            !isEntitiesLoading &&
            entities?.length
        ) {
            setActiveReportingEntityId(entities[0].id);
        }
    }, [entities, activeReportingEntityId]);

    usePageviewTrack("pageview_reporting");

    return (
        <Container>
            <BodyHeader
                dataChameleon={{
                    key: "reporting",
                    type: "tutorial",
                }}
                icon={MegaphoneIcon}
                title={t("page_title")}
                subTitle={t("page_subtitle")}
                action={<ReportingHeaderButton />}
            />

            <FlexCol p="4" gap="4" alignItems="start" alignSelf="stretch">
                <ReportingFilters>
                    {!!entities?.length && (
                        <EntitySelect
                            entityId={activeReportingEntityId}
                            setEntityId={setActiveReportingEntityId}
                        ></EntitySelect>
                    )}
                    <PeriodChipsSelect />
                </ReportingFilters>
                <IndicatorsTable />
            </FlexCol>
            <AddPeriodModal />
            <InactiveIndicatorsModal />
            <AddCustomIndicatorModal />
        </Container>
    );
};

export default ReportingV2;
