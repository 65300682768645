import { FlexCol } from "@design-system/Layout/Flex";
import { FC } from "react";
import { useCsrdPillar } from "../../data";
import Esrs from "../Esrs";
import { SectionSkeleton } from "@app/screens/Frameworks/framework/components/Section";
import { filterAtom } from "../../ctx";
import { useAtomValue } from "jotai";

export const PillarBody: FC<{ pillarId?: string }> = ({ pillarId }) => {
    const filter = useAtomValue(filterAtom)
    const { pillar, isPending } = useCsrdPillar(pillarId, filter);
    return pillar && !isPending ? (
        <FlexCol w="full" className="min-w-0" gap="4">
            {pillar.esrss.map((esrs, idx) => (
                <Esrs key={idx} cmsId={esrs.cmsId} pillarId={pillarId} />
            ))}
        </FlexCol>
    ) : (
        <FlexCol gap="4" w="full" p="4">
            {Array.from({ length: 2 }).map((_,i) =>(
            <SectionSkeleton key={i} />))}
        </FlexCol>
    );
};
