import {
    CsrdDatapointStatus,
    MaterialityStatus,
} from "@generated/client/graphql";
import { atom } from "jotai";
import { atomWithHash } from "jotai-location";
import { RESET } from "jotai/utils";
import {} from "react-router-dom";

//Navigation
export const activeDisclosureRequirementIdAtom = atomWithHash<
    string | undefined
>("drId", undefined);
export const activeDatapointIdAtom = atomWithHash<string | undefined>(
    "dpId",
    undefined,
);

export const setScrollAnchorAtom = atom(
    null,
    (_, set, anchor: { drId: string; dpId?: string }) => {
        set(activeDisclosureRequirementIdAtom, anchor.drId);
        set(activeDatapointIdAtom, anchor.dpId ?? RESET);
    },
);

// Search
export const searchStringAtom = atomWithHash<string | undefined>(
    "search",
    undefined,
);
export const ownerIdsFilterAtom = atom<(string | null)[] | undefined>(
    undefined,
);
export const statusesFilterAtom = atom<CsrdDatapointStatus[] | undefined>(
    undefined,
);

export const filterAtom = atom((get) => {
    return {
        search: get(searchStringAtom),
        ownerIds: !!get(ownerIdsFilterAtom)?.length
            ? get(ownerIdsFilterAtom)
            : undefined,
        statuses: !!get(statusesFilterAtom)?.length
            ? get(statusesFilterAtom)
            : undefined,
    };
});
export const userMultiSelectSearchAtom = atom<string | undefined>(undefined);

// Materiality Modal
export const openAtom = atom<boolean>(false);
const disclosureRequirementIdAtom = atom<string | undefined>(undefined);
const datapointIdAtom = atom<string | undefined>(undefined);
const statusAtom = atom<MaterialityStatus>(MaterialityStatus.Unknown);
const commentAtom = atom<string | undefined>(undefined);
const objectDescriptionAtom = atom<string | undefined>(undefined);

export const materialityModalCtxAtom = atom((get) => {
    return {
        open: get(openAtom),
        disclosureRequirementId: get(disclosureRequirementIdAtom),
        datapointId: get(datapointIdAtom),
        status: get(statusAtom),
        comment: get(commentAtom),
        description: get(objectDescriptionAtom),
    };
});

export const closeMaterialityModalAtom = atom(undefined, (_, set) => {
    set(openAtom, false);
    set(disclosureRequirementIdAtom, undefined);
    set(datapointIdAtom, undefined);
    set(statusAtom, MaterialityStatus.Unknown);
    set(commentAtom, undefined);
    set(objectDescriptionAtom, undefined);
});

export const openMaterialityModalAtom = atom(
    (get) => get(materialityModalCtxAtom),
    (
        _get,
        set,
        status: MaterialityStatus,
        comment?: string,
        datapointId?: string,
        disclosureRequirementId?: string,
        description?: string,
    ) => {
        set(openAtom, true);
        set(statusAtom, status);
        set(commentAtom, comment);
        set(datapointIdAtom, datapointId);
        set(disclosureRequirementIdAtom, disclosureRequirementId);
        set(objectDescriptionAtom, description);
    },
);
