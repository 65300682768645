import * as dayjs from "dayjs";
import "dayjs/locale/fr";

import { atom, useSetAtom } from "jotai";
import { useEffect } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import {
    GetCurrentUserQuery,
    User,
} from "../../../../generated/client/graphql";
import i18next from "../../../i18n";
import Avatar from "../../User/Avatar";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { Box } from "@design-system/Layout/Box";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useAuth0 } from "@auth0/auth0-react";

export const currentUserAtom = atom<User | undefined>(undefined);
export default function TopBarProfil({
    currentUser,
}: {
    currentUser?: GetCurrentUserQuery["currentUser"];
}) {
    const navigate = useNavigate();
    const params = useParams();
    const setCurrentUser = useSetAtom(currentUserAtom);
    const { t } = useTranslation("TopBar");
    const { logout } = useAuth0();

    useEffect(() => {
        const locale = currentUser?.locale;
        i18next.changeLanguage(locale);
        dayjs.locale(locale);
    }, [currentUser?.locale]);

    useEffect(() => {
        setCurrentUser(currentUser as User);
    }, [currentUser]);

    function handleClickSettings() {
        navigate(`/o/${params.org_uname}/admin/profile`);
    }

    function handleClickLogout() {
        logout({
            logoutParams: { returnTo: "https://beavr.tech" },
        });
    }

    return (
        <DropdownMenu>
            <DropdownMenu.Trigger asChild>
                <button className="flex items-center space-x-4 rounded-full border border-neutral-150 p-1 pr-3">
                    {currentUser && (
                        <Avatar
                            avatarUrl={currentUser.avatar?.signedUrl}
                            initials={currentUser.initials}
                            size="large"
                        />
                    )}
                    <HiChevronDown className="w-4 h-4 text-neutral-700" />
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end" sideOffset={2} hasPortal={true}>
                <FlexRow alignItems="center" px="2" py="2.5">
                    {currentUser && (
                        <Avatar
                            avatarUrl={currentUser.avatar?.signedUrl}
                            initials={currentUser.initials}
                            size="large"
                        />
                    )}
                    <Box ml="2.5">
                        <Text variant="body-bold">
                            {currentUser &&
                                currentUser?.firstName +
                                    " " +
                                    currentUser?.lastName}
                        </Text>
                        <Text variant="body-sm">
                            {currentUser && currentUser.email}
                        </Text>
                    </Box>
                </FlexRow>
                <DropdownMenu.Separator />
                <DropdownMenu.Item
                    onClick={handleClickSettings}
                    className="text-primary"
                >
                    <DropdownMenu.ItemIcon
                        name="settings"
                        size="sm"
                        className="text-brand"
                    />
                    {t("profile_settings")}
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={handleClickLogout}>
                    <DropdownMenu.ItemIcon
                        name="signOut"
                        size="sm"
                        className="text-brand"
                    />
                    {t("profile_log_out")}
                </DropdownMenu.Item>
                <DropdownMenu.Arrow />
            </DropdownMenu.Content>
        </DropdownMenu>
    );
}
