import { GraphQLUserRepository } from "@app/repositories/GraphQLRepositories/UserRepository";
import { UserRole, UserType } from "@generated/client/graphql";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { userKeys } from "./queryKeys";

const userRepository = new GraphQLUserRepository();

// read
export const usersAtom = atomWithQuery(() => {
    return {
        queryKey: [userKeys.users],
        queryFn: userRepository.get,
    };
});

export const currentUserAtom = atomWithQuery(() => {
    return {
        queryKey: [userKeys.currentUser],
        queryFn: userRepository.getCurrentUser,
        staleTime: Infinity,
    };
});

export const currentUserNoTenantAtom = atomWithQuery(() => {
    return {
        queryKey: [userKeys.currentUserNoTenant],
        queryFn: userRepository.getCurrentUserNoTenant,
        staleTime: Infinity,
    };
});

// read-only
export const currentUserRoleAtom = atom((get) => {
    const currentUserQuery = get(currentUserAtom);
    const role = currentUserQuery?.data?.currentUser.permission?.role;
    if (!role && !currentUserQuery?.isPending) {
        throw new Error("Role not found");
    }

    const isBeavrAdmin = role === UserRole.BeavrAdmin;
    const isAdmin = role === UserRole.Admin;
    const isCustomer = role === UserRole.Contributor;
    const isSupplier = role === UserRole.Supplier;

    return {
        role,
        isPending: currentUserQuery.isPending,
        isBeavrAdmin,
        isAdmin,
        isCustomer,
        isSupplier,
    };
});

export const currentUserIsBeavrAdminAtom = atom((get) => {
    const { role } = get(currentUserRoleAtom);

    return role === UserRole.BeavrAdmin;
});
// read-only
export const currentUserIsBeavrAtom = atom((get) => {
    const currentUserNoTenantQuery = get(currentUserNoTenantAtom);
    const type = currentUserNoTenantQuery?.data?.currentUserNoTenant?.type;
    const isBeavrUser = [UserType.Beavr, UserType.BeavrSuperAdmin].includes(
        type || UserType.Customer,
    );
    return { isBeavrUser, isPending: currentUserNoTenantQuery.isPending };
});

export const currentUserOrgsAtom = atomWithQuery(() => {
    return {
        queryKey: [userKeys.currentUserOrgsAtom],
        queryFn: userRepository.getCurrentUserOrgs,
        staleTime: Infinity,
    };
});
