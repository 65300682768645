import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const addPeriodModalIsOpenAtom = atom<boolean>(false);
export const inactiveIndicatorsModalIsOpenAtom = atom<boolean>(false);
export const createCustomIndicatorsModalIsOpenAtom = atom<boolean>(false);

export const activeDatapointGroupIdsAtom = atomWithStorage(
    "reportingDatagroup",
    [] as string[],
);

export const activeReportingEntityIdAtom = atom<string>("");
