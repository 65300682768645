import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonSmall from "@app/components/Button/ButtonSmall";
import Loading from "@app/components/Loading";
import { useEntities } from "@app/store/entityStore";

import Select from "@design-system/Inputs/Select";
import { ChevronDown } from "lucide-react";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

const EntitySelect: FC<
    PropsWithChildren<{
        entityId: string | undefined;
        setEntityId: (entityId: string) => void;
    }>
> = ({ entityId, setEntityId }) => {
    const { entities, isLoading } = useEntities();
    const { t } = useTranslation("EntitySelect");

    return isLoading ? (
        <Loading size="small" />
    ) : (
        <Select
            value={entityId}
            onValueChange={(entityId: string) => {
                const currentEntity = entities?.find(
                    (elt) => elt.id === entityId,
                );

                currentEntity && setEntityId(currentEntity?.id);
            }}
        >
            <Select.Trigger asChild>
                <ButtonSmall
                    variant={ButtonType.OUTLINED}
                    IconRight={ChevronDown}
                    className="w-[140px] justify-between"
                >
                    <div className="font-medium text-sm truncate">
                        {entities?.find((elt) => elt.id === entityId)?.name ||
                            t("placeholder")}
                    </div>
                </ButtonSmall>
            </Select.Trigger>
            <Select.Content className="max-h-[260px] overflow-auto">
                {entities?.map((entity) => (
                    <Select.Item key={entity.id} value={entity.id}>
                        <div className="group-hover:text-brand_hover text-sm">
                            {entity.name}
                        </div>
                    </Select.Item>
                ))}
            </Select.Content>
        </Select>
    );
};

export default EntitySelect;
