import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { CsrdRepository } from "@app/repositories/CsrdRepository";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import {
    GetCsrdDatapointDocument,
    GetCsrdDatapointsDocument,
    GetCsrdPillarDataDocument,
    GetCsrdPillarsDocument,
    GetCsrdSectionsAndStatsDocument,
    GetCsrdStatsDocument,
    UpdateCsrdDatapointDocument,
    UpdateCsrdDisclosureRequirementDocument,
    UpdateCsrdEsrsDocument,
    type CsrdDatapointPatch,
    type CsrdDatapointsFilter,
    type CsrdDisclosureRequirementPatch,
    type CsrdEsrsPatch,
    type GetCsrdDatapointQueryVariables,
    type GetCsrdDatapointsQueryVariables,
    type GetCsrdSectionsAndStatsQuery,
    type GetCsrdStatsQuery,
    type UpdateCsrdDatapointMutation,
    type UpdateCsrdDisclosureRequirementMutation,
} from "@generated/client/graphql";

export class GraphQLCsrdRepository implements CsrdRepository {
    getGlobalStats = (): Promise<GetCsrdStatsQuery> => {
        return graphqlClientKoyeb.request(
            GetCsrdStatsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    getSectionsAndStats = (): Promise<GetCsrdSectionsAndStatsQuery> => {
        return graphqlClientKoyeb.request(
            GetCsrdSectionsAndStatsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    getPillar = (id: string, filter?: CsrdDatapointsFilter) => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarDataDocument,
            { pillarCmsId: id, filter: filter },
            AccessHeadersHolder.getHeaders(),
        );
    };

    getPillars = () => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    getDatapoint = (input: GetCsrdDatapointQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetCsrdDatapointDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };

    getDatapoints = (input: GetCsrdDatapointsQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetCsrdDatapointsDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };

    updateDatapoint = (input: {
        id: string;
        patch: CsrdDatapointPatch;
    }): Promise<UpdateCsrdDatapointMutation> => {
        return graphqlClientKoyeb.request(
            UpdateCsrdDatapointDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };

    updateDisclosureRequirement = (input: {
        id: string;
        patch: CsrdDisclosureRequirementPatch;
    }): Promise<UpdateCsrdDisclosureRequirementMutation> => {
        return graphqlClientKoyeb.request(
            UpdateCsrdDisclosureRequirementDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };

    updateEsrs = (id: string, patch: CsrdEsrsPatch) =>
        graphqlClientKoyeb.request(
            UpdateCsrdEsrsDocument,
            { id, patch },
            AccessHeadersHolder.getHeaders(),
        );
}
