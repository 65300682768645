import { GaugeCircular } from "@app/components/Progress/ProgressCircular/GaugeCircular";
import FrameworkSection from "@app/screens/Frameworks/framework/components/Section";
import { UserAssign } from "@app/shared/components/UserAssign";
import { useConditionalScrollTo } from "@app/shared/utils/scroll";
import { Icon } from "@design-system/Icon";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue, useSetAtom } from "jotai";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { computeDatapointStats } from "../../../services";
import {
    activeDisclosureRequirementIdAtom,
    openMaterialityModalAtom,
} from "../../ctx";
import { useUpdateDisclosureRequirementOwner } from "../../data";
import { type PillarDisclosureRequirement } from "../../types";
import { DatapointForm } from "../DatapointForm";

type DisclosureRequirementProps = {
    disclosureRequirement: PillarDisclosureRequirement;
};

export const DisclosureRequirement: FC<DisclosureRequirementProps> = ({
    disclosureRequirement: {
        id,
        cmsId,
        datapoints,
        name,
        ownerIds,
        materiality: { status, comment },
    },
}) => {
    const { t } = useTranslation("FrameworkDatapoint");

    const selectedDisclosureRequirementId = useAtomValue(
        activeDisclosureRequirementIdAtom,
    );

    const { notMaterial, total, validated } = computeDatapointStats(datapoints);
    const openMaterialityModal = useSetAtom(openMaterialityModalAtom);

    const handleMaterialityModalSelect = () => {
        openMaterialityModal(status, comment || "", undefined, id, name);
    };
    const done = validated + notMaterial;

    const { mutate: handleSelect } = useUpdateDisclosureRequirementOwner(id);

    const scrollToDisclosureRequirementOnSelection = useConditionalScrollTo(
        typeof cmsId === "string" && cmsId === selectedDisclosureRequirementId,
    );

    return (
        <FrameworkSection.Block className="w-full">
            <FrameworkSection.BlockHeader
                id={cmsId}
                ref={scrollToDisclosureRequirementOnSelection}
            >
                <FlexRow
                    gap="7"
                    py="2"
                    pl="2"
                    justifyContent="between"
                    alignItems="center"
                >
                    <Text variant="header2">{name}</Text>
                    <FlexRow
                        gap="8"
                        justifyContent="between"
                        alignItems="center"
                    >
                        <UserAssign
                            enableNone
                            selectedUsers={ownerIds}
                            onSelect={handleSelect}
                            selectMode="single"
                        />
                        <FlexRow
                            gap="2.5"
                            justifyContent="between"
                            alignItems="center"
                            minW="fit"
                        >
                            <Text variant="body">
                                {done} / {total}
                            </Text>
                            <GaugeCircular
                                size={20}
                                progress={(done / total) * 100}
                            />
                            <DropdownMenu modal={false}>
                                <DropdownMenu.Trigger>
                                    <Icon name="more" />
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content>
                                    <DropdownMenu.Item
                                        onSelect={handleMaterialityModalSelect}
                                    >
                                        <DropdownMenu.ItemIcon
                                            name="eyeOff"
                                            className="text-black"
                                        />
                                        <FlexRow alignItems="center">
                                            <Text variant="body" color="dark">
                                                {t("manage_materiality")}
                                            </Text>
                                        </FlexRow>
                                    </DropdownMenu.Item>
                                </DropdownMenu.Content>
                            </DropdownMenu>
                        </FlexRow>
                    </FlexRow>
                </FlexRow>
            </FrameworkSection.BlockHeader>
            <FrameworkSection.BlockBody className="w-full">
                {datapoints.map((datapoint, idx) => (
                    <DatapointForm datapoint={datapoint} key={idx} />
                ))}
            </FrameworkSection.BlockBody>
        </FrameworkSection.Block>
    );
};
